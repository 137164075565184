import React from 'react';
import { TextField, FormControl, FormHelperText, Box } from '@mui/material';
import { Controller } from 'react-hook-form';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

export default function InputDatePickerTime({ name, control, label, rules, defaultValue = null, externalError, sx = {} }) {
    const isError = !!externalError || !!control._formState.errors[name];

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'pt'}>
            <FormControl fullWidth error={isError} sx={sx}>
                <Controller
                    name={name}
                    control={control}
                    defaultValue={defaultValue}
                    rules={rules}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                        <DateTimePicker
                            label={label}
                            value={value}
                            onChange={onChange}
                            onBlur={onBlur}
                            ref={ref}
                            slotProps={{
                                textField: {
                                    error: isError
                                }
                            }}
                            renderInput={(params) => <TextField {...params} fullWidth error={!!isError} lg={{ width: '100%' }} />}
                        />
                    )}
                />
                <Box sx={{ minHeight: '25px' }}>
                    {isError && <FormHelperText sx={{ marginLeft: '0px' }}>{externalError?.message || control._formState.errors[name]?.message}</FormHelperText>}
                </Box>
            </FormControl>
        </LocalizationProvider>
    );
}
