import React from 'react';
import {Autocomplete,TextField,FormControl,FormHelperText,Box,IconButton,ListItemText,useMediaQuery,useTheme,Modal,Popper} from '@mui/material';
import {Controller} from 'react-hook-form';
import {Add} from '@mui/icons-material';
import {IconX} from '@tabler/icons';
import {useDispatch} from 'react-redux';
import {showSnackbar} from 'store/SnackBar/actions';
import axiosConfig from 'config/AxiosConfig';
import InputsFake from './InputsFake';
import getNestedValue from 'utils/getNestedValue';
import {LoadingButton} from '@mui/lab';

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase()+string.substring(1);
}


export default function InputAutocompleteAdd({isPrioridade=false,isFakeInput,name,control,label,rules,options,defaultValue=null,externalError,disabled=false,sx={},StateProps,renderComponents=[],rotaBase='',setOptions=() => {},setValue=() => {},container=document.getElementById('root-mui'),optionLabel='tipo',...props}) {
  const isError=(!!externalError||!!control._formState.errors[name]);
  // StateProps = {tipo: '', rota: '', descricao: '',  ...}
  const [openModal,setOpenModal]=React.useState(false);
  const [state,setState]=React.useState(StateProps);
  const [loading,setLoading]=React.useState(false);
  const theme=useTheme();
  const sm=useMediaQuery(theme.breakpoints.down('sm'));
  const dispatch=useDispatch();

  const style={
    position: 'absolute',
    right: '0',
    width: '100px',
    height: '100%',
    overflowY: 'scroll',
    border: '2px solid #000',
    backgroundColor: theme.palette.background.default,
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

  const handleClose=() => {
    setOpenModal(false);
    setState(StateProps);
  }

  const onSubmit=async () => {
    try {
      setLoading(true);
      const response=await axiosConfig.post(`/${rotaBase}/cadastrar`,{data: [state]})
      const newOption=response.data.data.objetos[0]
      setOptions([...options,{
        value: newOption.id,
        label: newOption?.[optionLabel],
      }]);
      setValue(name,newOption.id);
      dispatch(showSnackbar('Cadastro Realizado com sucesso','success'));
      handleClose();
    } catch(e) {
      console.log(e);
      dispatch(showSnackbar('Erro ao realizar cadastro','error'));
    } finally {
      setLoading(false);
    }
  };

  if(isFakeInput) {
    const value=options.find((option) => option.value===getNestedValue(control._formValues,name))?.label||'';
    return <InputsFake type='text' label={label} value={value} />;
  }

  return (
    <>
      <FormControl fullWidth error={isError} sx={sx}>
        <Controller
          name={name}
          control={control}
          defaultValue={defaultValue}
          rules={rules}
          render={({field: {onChange,value,...field}}) => (
            <Autocomplete
              {...field}
              disabled={disabled}
              disableClearable
              options={options}
              PopperComponent={(props) => <Popper container={() => container} {...props} />}
              getOptionLabel={(option) => option.label}
              sx={{width: '100%'}}
              label={label}
              renderOption={(props,option) => {
                if(props?.['data-option-index']===0&&option.value==='add') {
                  return (
                    <li {...props} style={{background: 'rgb(233, 233, 233)'}} key={option.key} onClick={() => setOpenModal(true)}>
                      <ListItemText>{capitalizeFirstLetter(option.label)}</ListItemText>
                      <IconButton sx={{ml: 'auto',p: 0}}>
                        <Add />
                      </IconButton>
                    </li>
                  )
                }
                if(isPrioridade) {
                  return (
                    <li {...props} key={option.key} >
                      <ListItemText sx={{color: option.bgColor}}>{capitalizeFirstLetter(option.label)}</ListItemText>
                    </li>
                  )
                }
                return (
                  <li {...props} key={option.key}>
                    <ListItemText>{capitalizeFirstLetter(option.label)}</ListItemText>
                  </li>
                )
              }}
              ListboxProps={{
                style: {
                  // backgroundColor: theme.palette.grey[50],
                  paddingTop: '10px',
                  border: '1px solid #e2e2e2',
                  ...props.ListboxProps
                }
              }}
              renderInput={(params) => {
                const bgColor=isPrioridade? options.filter((option) => {return option.value===value})[0]:{bgColor: 'black'}
                return (
                  <TextField {...params} InputLabelProps={{shrink: true}} sx={{color: bgColor?.bgColor}} label={label} fullWidth error={isError} />
                )
              }}
              onChange={(_,newValue) => onChange(newValue? newValue.value:null)}
              value={options.find((option) => option.value===value)||null}
            />
          )}
        />
        <Box sx={{minHeight: '25px'}}>
          {isError&&(
            <FormHelperText sx={{marginLeft: '0px'}}>{externalError?.message||control._formState.errors[name]?.message}</FormHelperText>
          )}
        </Box>
      </FormControl>
      <Modal
        anchor="right"
        open={openModal}
        container={() => document.getElementById('root-mui')}
        onClose={handleClose}
        variant="temporary"
        elevation={16}
        timeout={200}
        PaperProps={{
          sx: {
            width: sm? '100%':'500px !important',
          }
        }}>
        <Box
          sx={{...style,width: sm? '100%':'500px !important'}}>
          <Box sx={{display: 'flex',alignItems: 'center'}}>
            <h2 id="parent-modal-title">{'Adicionar'+' '+label}</h2>
            <IconButton sx={{ml: 'auto'}} onClick={handleClose}>
              <IconX />
            </IconButton>
          </Box>
          {
            renderComponents.map((component,index) => {
              if(component.type==='text') {
                return (
                  <TextField
                    key={index}
                    label={component.label}
                    variant="outlined"
                    fullWidth
                    onChange={(e) => setState({...state,[component.name]: e.target.value})}
                    value={state[component.name]||''}
                    sx={{mb: 2}}
                  />
                )
              }
              if(component.type==='autocomplete') {
                return (
                  <Autocomplete
                    key={index}
                    options={component.options}
                    getOptionLabel={(option) => option.label}
                    onChange={(_,newValue) => setState({...state,[component.name]: newValue.value})}
                    renderInput={(params) => <TextField {...params} label={component.label} variant="outlined" />}
                    sx={{mb: 2}}
                  />
                )
              }
            })
          }
          <LoadingButton
            size="small"
            type="button"
            onClick={onSubmit}
            loading={loading}
            disabled={loading}
            variant="contained"
            sx={{width: '100%',mt: 2,height: '40px',fontSize: '15px'}}
          >

            {
              loading? '':'Salvar'
            }
          </LoadingButton>
        </Box>
      </Modal>
    </>
  );
}
