import * as React from 'react';
import Div from 'ui-component/layouts/components/Div';
import CardEquipe from './Card';
import {useForm} from 'react-hook-form';
import InputAutocompleteTag from 'components/InputAutocompleteTag';
import Carousel from 'react-material-ui-carousel';

const dataEquipes=[{
    id: 1,
    nome_head: 'Ivan Netto',
    id_head: 'ivan.netto',
    espaco_head: 60,
    colaboradores: [
        {
            nome: 'Vanessa Ratti',
            id: 'vanessa.ratti',
            espaco: 80,
            equipes: [
                {
                    nome: 'Catarina Marrão | Yasmim Quatrini',
                    slots: [
                        {
                            nome: 'Funcional Health Tech',
                            valor: 12700
                        },
                        {
                            nome: 'Nubimetrics',
                            valor: 10175
                        },
                        {
                            nome: 'Winnin (Até 14.11)',
                            valor: 9980
                        },
                        {
                            nome: 'weme (Até 09.11)',
                            valor: 7900
                        },
                        {
                            nome: 'Sheylli Caleffi (Job 40 dias)',
                            valor: 5500
                        },
                    ],
                    ticket_medio: 9255,
                    valor_total: 46275
                }
            ]
        },
        {
            nome: 'Mariana Cruz',
            id: 'mariana.cruz',
            espaco: 70,
            equipes: [
                {
                    nome: 'Catarina Marrão | Yasmim Quatrini',
                    slots: [
                        {
                            nome: 'Rakuten',
                            valor: 200000
                        },
                        {
                            nome: 'Wayra',
                            valor: 112820
                        },
                        {
                            nome: 'OpenCo',
                            valor: 9200
                        },
                        {
                            nome: 'Trademaster',
                            valor: 9000
                        },
                        {
                            nome: '',
                            valor: 0
                        },
                    ],
                    ticket_medio: 9255,
                    valor_total: 46275
                }
            ]
        }
    ],
},
{
    id: 2,
    nome_head: 'Ivan Netto 2.0',
    id_head: 'ivan.netto',
    espaco_head: 60,
    colaboradores: [
        {
            nome: 'Vanessa Ratti',
            id: 'vanessa.ratti',
            espaco: 80,
            equipes: [
                {
                    nome: 'Catarina Marrão | Yasmim Quatrini',
                    slots: [
                        {
                            nome: 'Funcional Health Tech',
                            valor: 12700
                        },
                        {
                            nome: 'Nubimetrics',
                            valor: 10175
                        },
                        {
                            nome: 'Winnin (Até 14.11)',
                            valor: 9980,
                            data_final: '2025-01-01'
                        },
                        {
                            nome: 'weme (Até 09.11)',
                            valor: 7900,
                            data_final: '2024-11-09'
                        },
                        {
                            nome: 'Sheylli Caleffi (Job 40 dias)',
                            valor: 5500,
                            data_final: '2024-12-11'
                        },
                    ],
                    ticket_medio: 9255,
                    valor_total: 46275
                }
            ]
        },
        {
            nome: 'Mariana Cruz',
            id: 'mariana.cruz',
            espaco: 70,
            equipes: [
                {
                    nome: 'Catarina Marrão | Yasmim Quatrini',
                    slots: [
                        {
                            nome: 'Rakuten',
                            valor: 200000
                        },
                        {
                            nome: 'Wayra',
                            valor: 112820
                        },
                        {
                            nome: 'OpenCo',
                            valor: 9200
                        },
                        {
                            nome: 'Trademaster',
                            valor: 9000
                        },
                        {
                            nome: '',
                            valor: 0
                        },
                    ],
                    ticket_medio: 9255,
                    valor_total: 46275
                }
            ]
        }
    ],
}]

export default function Teste() {
    const {
        control,
        watch,
    }=useForm({
        defaultValues: {
            equipe: []
        }
    })

    const equipeState=watch('equipe')

    return (
        <>
            <Div>
                <InputAutocompleteTag
                    control={control}
                    name={'equipe'}
                    label={'Equipe'}
                    options={[
                        {value: 1,label: "Ivan Netto"},
                        {value: 2,label: "Ivan Netto 2.0"},
                    ]}
                />
            </Div>
            <Div sx={{display: 'flex',width: '100%',overflowX: 'visible',padding: 2}}>
                <Carousel navButtonsAlwaysInvisible={false} sx={{width: '100%'}} autoPlay={false}>
                    {dataEquipes.filter((equipe) => {
                        if(equipeState.length===0) return true
                        const equipeId=equipeState.map((id) => id.value)
                        return equipeId.includes(equipe.id)
                    }).map((equipe) => (
                        <CardEquipe key={equipe.nome_head} data={equipe} />
                    ))}
                </Carousel>
            </Div>
        </>
    )
}
