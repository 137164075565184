import PropTypes from 'prop-types';

// material-ui
import {useTheme} from '@mui/material/styles';
import {Avatar,Box,ButtonBase,Drawer,ListItemButton,ListItemIcon,ListItemText,Typography,useMediaQuery} from '@mui/material';

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';
import {BrowserView,MobileView} from 'react-device-detect';

// project imports
import MenuList from './MenuList';
import LogoSection from '../LogoSection';
import {drawerWidth} from 'store/constant';
import {useSelector} from 'react-redux';
import {IconLogout,IconMenu2} from '@tabler/icons';
import axiosConfig from 'config/AxiosConfig';
import {MenuOpen} from '@mui/icons-material';
import {useConfigMenu} from 'contexts/ConfigMenu';

// ==============================|| SIDEBAR DRAWER ||============================== //

const Sidebar=({drawerOpen,drawerToggle,window}) => {
  const theme=useTheme();
  const matchUpMd=useMediaQuery(theme.breakpoints.up('md'));
  const leftDrawerOpened=useSelector((state) => state.customization.opened);
  const {logout} = useConfigMenu();

  const drawer=(
    <>
      <BrowserView>
        <Box
          sx={{
            width: 260,
            display: 'flex',
            [theme.breakpoints.down('md')]: {
              // width: 'auto',
              bgcolor: 'inherit !important',
            },
            [theme.breakpoints.up('md')]: {
              // boxShadow: leftDrawerOpened? '9px 13px 15px -3px rgba(0,0,0,0.1)':'',
            },
            alignItems: 'center',
            // bgcolor: leftDrawerOpened? theme.palette.background.default:'inherit',
            p: '16px'
          }}
        >
          <Box component="span" sx={{flexGrow: 1}}>
            <LogoSection />
          </Box>
          <ButtonBase sx={{borderRadius: '50%',overflow: 'hidden',display: {xs: 'none',md: 'block'}}}>
            <Avatar
              variant="rounded"
              sx={{
                ...theme.typography.commonAvatar,
                ...theme.typography.mediumAvatar,
                transition: 'all .2s ease-in-out',
                bgcolor: 'white !important',
                ':hover': {bgcolor: '#c6c7e2 !important'},
                color: 'white',
                '&[aria-controls="menu-list-grow"],&:hover': {
                  backgroundColor: '#2196f3',
                }
              }}
              onClick={drawerToggle}
              color="inherit"
            >
              <MenuOpen sx={{color: 'gray !important'}} stroke={1.5} size="1.3rem" />
            </Avatar>
          </ButtonBase>
        </Box>
        <PerfectScrollbar
          component="div"
          style={{
            height: !matchUpMd? '100vh':'calc(100vh - 88px)',
          }}
        >
          <MenuList />
          <ListItemButton
            sx={{
              borderRadius: `10 px`,
              mb: 0.5,
              alignItems: 'flex-start',
              backgroundColor: 'inherit',
              py: 1.25,
              pl: `${24}px`
            }}
            onClick={async () => {
              try {
                await axiosConfig.post(`/api/logout`,{})
                logout()
              } catch(e) {
                console.log(e);
                logout()
              }
            }}
          >
            <ListItemIcon sx={{my: 'auto',minWidth: 36}}>{<IconLogout />}</ListItemIcon>
            <ListItemText
              primary={
                <Typography variant={'body1'} color="inherit">
                  Logout
                </Typography>
              }
            />
          </ListItemButton>
        </PerfectScrollbar>
      </BrowserView>
      <MobileView>
        <Box sx={{px: 2}}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              [theme.breakpoints.down('md')]: {
                // width: 'auto',
                bgcolor: 'inherit !important',
              },
              [theme.breakpoints.up('md')]: {
                // boxShadow: leftDrawerOpened? '9px 13px 15px -3px rgba(0,0,0,0.1)':'',
              },
              alignItems: 'center',
              // bgcolor: leftDrawerOpened? theme.palette.background.default:'inherit',
              p: '16px'
            }}
          >
            <Box component="span">
              <LogoSection />
            </Box>
            <ButtonBase sx={{borderRadius: '12px',overflow: 'hidden'}}>
              <Avatar
                variant="rounded"
                sx={{
                  ...theme.typography.commonAvatar,
                  ...theme.typography.mediumAvatar,
                  transition: 'all .2s ease-in-out',
                  background: `${theme.palette.reso.orange}`,
                  color: 'white',
                  '&[aria-controls="menu-list-grow"],&:hover': {
                    backgroundColor: '#2196f3',
                  }
                }}
                onClick={drawerToggle}
                color="inherit"
              >
                <IconMenu2 stroke={1.5} size="1.3rem" />
              </Avatar>
            </ButtonBase>
          </Box>
          <MenuList />
          <ListItemButton
            sx={{
              borderRadius: `10 px`,
              mb: 0.5,
              alignItems: 'flex-start',
              backgroundColor: 'inherit',
              py: 1.25,
              pl: `${24}px`
            }}
            onClick={async () => {
              try {
                await axiosConfig.post(`/api/logout`,{})
                logout()
              } catch(e) {
                console.log(e);
                logout()
              }
            }}
          >
            <ListItemIcon sx={{my: 'auto',minWidth: 36}}>{<IconLogout />}</ListItemIcon>
            <ListItemText
              primary={
                <Typography variant={'body1'} color="inherit">
                  Logout
                </Typography>
              }
            />
          </ListItemButton>
        </Box>
      </MobileView>
    </>
  );

  const container=window!==undefined? () => window.document.body:undefined;

  return (
    <Box component="nav" sx={{flexShrink: {md: 0},width: matchUpMd? drawerWidth:'auto'}} aria-label="mailbox folders">
      <Drawer
        container={container}
        variant={matchUpMd? 'persistent':'temporary'}
        anchor="left"
        open={drawerOpen}
        onClose={drawerToggle}
        sx={{
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            background: theme.palette.background.default,
            color: theme.palette.text.primary,
            boxShadow: leftDrawerOpened? '9px 10px 15px -3px rgba(0,0,0,0.1)':'',
            borderRight: 'none',
          },
        }}
        ModalProps={{keepMounted: true}}
        color="inherit"
      >
        {drawer}
      </Drawer>
    </Box>
  );
};

Sidebar.propTypes={
  drawerOpen: PropTypes.bool,
  drawerToggle: PropTypes.func,
  window: PropTypes.object
};

export default Sidebar;
