import {Visibility,VisibilityOff} from "@mui/icons-material";
import {Box,Button,IconButton,InputAdornment,InputLabel,Modal,OutlinedInput,Typography} from "@mui/material";
import axiosConfig from "config/AxiosConfig";
import {useConfigMenu} from "contexts/ConfigMenu";
import {useState} from "react";

const styleDelete={
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.default',
    border: '2px solid #000',
    boxShadow: 24,
};

export default function ConfirmModal({openModal,setOpenModal,confirm}) {
    const [password,setPassword]=useState('');
    const [error,setError]=useState(false);
    const [showPassword,setShowPassword]=useState(false);
    const [isLoading,setIsLoading]=useState(false);
    const {user}=useConfigMenu();


    const handleClickShowPassword=() => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword=(event) => {
        event.preventDefault();
    };

    const handleClose=() => {
        setOpenModal(false);
        setPassword('');
        setError(false);
        setShowPassword(false);
    }

    const confirmFunction=async () => {
        try {
            setIsLoading(true);
            await axiosConfig.post('/api/login',{
                email: user.email,
                password: password
            });
            confirm();
            handleClose();
        } catch(error) {
            console.log(error);
            setError(true);
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <Modal
            open={openModal}
            onClose={() => handleClose()}
            sx={{zIndex: 9999}}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={styleDelete}>
                <Box sx={{position: 'relative'}}>
                    {
                        isLoading&&(<Box sx={{position: 'absolute',zIndex: 999999, display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#0000004f', width: '100%', height: '100%'}}>
                            <div className="loader"></div>
                        </Box>)
                    }
                    <Box sx={{p: 4}}>
                        <Typography id="modal-modal-title" variant="h3" component="h2" sx={{marginBottom: '20px'}}>
                            Deseja realmente salvar?
                        </Typography>
                        <Box sx={{display: 'flex',flexDirection: 'column',gap: 1}}>
                            <InputLabel shrink={true} sx={{marginBottom: '-10px'}} htmlFor="outlined-adornment-email-login">Confirme sua senha</InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-email-login"
                                variant="outlined"
                                type={showPassword? 'text':'password'}
                                value={password}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                            size="large"
                                        >
                                            {showPassword? <Visibility />:<VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                onChange={(e) => setPassword(e.target.value)}
                                sx={{width: '100',marginBottom: !error? '20px':'0px'}}
                            />
                            {
                                error&&<Typography variant="caption" sx={{marginBottom: '20px'}} color="error">Senha incorreta</Typography>
                            }
                        </Box>
                        <Box sx={{display: 'flex',justifyContent: 'space-between'}}>
                            <Button variant="contained" color="error" onClick={() => {handleClose()}}>Cancelar</Button>
                            <Button variant="contained" color="primary" onClick={() => {confirmFunction()}}>Confirmar</Button>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Modal>
    )
}