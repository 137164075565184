import {useDispatch,useSelector} from 'react-redux';
import {Outlet} from 'react-router-dom';

// material-ui
import {styled} from '@mui/material/styles';
import {AppBar,Box,CssBaseline,GlobalStyles,Toolbar,useMediaQuery} from '@mui/material';

// project imports
import Breadcrumbs from 'ui-component/extended/Breadcrumbs';
import Header from './Header';
import Sidebar from './Sidebar';
import Customization from '../Customization';
import {drawerWidth} from 'store/constant';
import {SET_MENU} from 'store/actions';

// assets
import {IconChevronRight} from '@tabler/icons';
import GlobalSnackbar from 'components/GlobalSnackbar';
import {useRef} from 'react';
import {useContext} from 'react';
import {ConfigMenuContext} from 'contexts/ConfigMenu';
import FeedbackButton from './Feedback/FeedbackButton';
import {useTheme} from '@mui/material';
import {useEffect} from 'react';
import {useState} from 'react';

// styles
const Main=styled('main',{shouldForwardProp: (prop) => prop!=='open'})(({theme,open}) => ({
  ...theme.typography.mainContent,
  borderBottomLeftRadius: 0,
  borderBottomRightRadius: 0,
  transition: theme.transitions.create(
    'margin',
    open
      ? {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen
      }
      :{
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      }
  ),
  [theme.breakpoints.up('md')]: {
    marginLeft: open? 0:-(drawerWidth),
    width: `calc(100% - ${drawerWidth}px)`
  },
  [theme.breakpoints.down('md')]: {
    marginLeft: '20px',
    width: `calc(100% - ${drawerWidth}px)`,
    padding: '16px'
  },
  [theme.breakpoints.down('sm')]: {
    marginLeft: '10px',
    width: `calc(100% - ${drawerWidth}px)`,
    padding: '16px',
    marginRight: '10px'
  }
}));

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout=() => {
  const theme=useTheme();
  const matchDownMd=useMediaQuery(theme.breakpoints.down('md'));
  const tableRef=useRef(null);
  // Handle left drawer
  const leftDrawerOpened=useSelector((state) => state.customization.opened);
  const dispatch=useDispatch();
  const handleLeftDrawerToggle=() => {
    dispatch({type: SET_MENU,opened: !leftDrawerOpened});
  };
  const menu=useContext(ConfigMenuContext);
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 50);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  if(menu.loading) return null;

  return (
    <Box id='root-mui' sx={{display: 'flex',backgroundColor: theme.palette.primary.light}} ref={tableRef}>
      <GlobalSnackbar />
      <GlobalStyles styles={{
        '.MuiInputBase-root.Mui-disabled': {
          background: `${theme.primaryLightDisabled} !important`,
          cursor: 'not-allowed !important',
        },
        '.country.highlight': {
          backgroundColor: `${theme.palette.primary.light} !important`,
          color: `${theme.textDark} !important`
        },
        '.custom-phone-dropdown': {
          backgroundColor: `${theme.palette.background.default} !important`,
          color: `${theme.textDark} !important`,
          border: '1px solid #354868 !important',
        },
        '.custom-phone-input': {
          width: '100% !important',
          height: '100% !important',
          padding: '15.5px 14px 15.5px 54px !important',
          backgroundColor: `${theme.palette.primary.light} !important`,
          color: `${theme.textDark} !important`,
          border: '1px solid #354868 !important',
          fontSize: '0.875rem !important',
          fontWeight: '400 !important',
          fontFamily: 'Roboto, sans-serif !important',
          lineHeight: '1.4375em !important',
        },
        '.ql-container': {
          background: `${theme.palette.primary.light} !important`
        },
        '.ql-bubble': {
          background: `${theme.palette.primary.light} !important`,
          paddingBottom: '20px',
        },
        '.ql-toolbar.ql-snow': {
          border: `0px solid #354868 !important`,
          borderInline: `1px solid #354868 !important`,
          borderTop: `0px solid #354868 !important`,
          borderBottom: `1px solid #354868 !important`,
          display: 'flex',
          justifyContent: 'end',
          paddingTop: '10px',
          background: `${theme.palette.primary.light} !important`,
        }
      }} />
      <CssBaseline />
      {/* header */}
      <AppBar
        enableColorOnDark
        position="fixed"
        color="inherit"
        elevation={0}
        sx={{
          padding: '0px !important',
          bgcolor: '#eef2f6',
          transition: leftDrawerOpened? theme.transitions.create('width'):'none',
        }}
      >
        <Toolbar sx={{p: '0 !important',pr: '24px !important',backgroundColor: scrolled ? 'white' : theme.palette.primary.light, transition: 'auto', boxShadow: scrolled && '9px 10px 15px -3px rgba(0,0,0,0.1)' }}>
          <Header handleLeftDrawerToggle={handleLeftDrawerToggle} tableRef={tableRef} leftDrawerOpened={leftDrawerOpened} scrolled={scrolled} />
        </Toolbar>
      </AppBar>

      {/* drawer */}
      <Sidebar drawerOpen={!matchDownMd? leftDrawerOpened:!leftDrawerOpened} drawerToggle={handleLeftDrawerToggle} />

      {/* main content */}
      <Main theme={theme} open={leftDrawerOpened} >
        {/* breadcrumb */}
        <Breadcrumbs separator={IconChevronRight} navigation={menu} icon title rightAlign />
        <Outlet />
      </Main>
      <FeedbackButton />
      <Customization />
    </Box>
  );
};

export default MainLayout;
