import React from 'react';
import {Autocomplete,TextField,FormControl,FormHelperText,Box,Checkbox} from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

import {Controller} from 'react-hook-form';

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.substring(1);
}

export default function InputAutocompleteTag({
  name,
  control,
  label,
  rules,
  options,
  disabled,
  defaultValue,
  ListboxProps,
  externalError,
  ...params
}) {
  const isError=!!externalError||!!control._formState.errors[name];

  return (
    <FormControl fullWidth error={isError}>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        rules={rules}
        disabled={disabled}
        render={({field}) => (
          <Autocomplete
            {...field}
            {...params}
            disableCloseOnSelect
            disableClearable
            options={options}
            getOptionLabel={(option) => capitalizeFirstLetter(option.label)}
            getOptionDisabled={(option) => { 
              const todosField=field.value.some((item) => item.value==='todos');
              if(todosField) {
                return option.value!=='todos';
              }
            }}
            isOptionEqualToValue={(option,value) => option.value===value.value}
            renderOption={(props,option,{selected}) => (
              <Box {...props}>
                <Checkbox
                  icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                  checkedIcon={<CheckBoxIcon fontSize="small" />}
                  checked={selected}
                />
                {capitalizeFirstLetter(option.label)}
              </Box>
            )}
            multiple
            sx={{width: '100%'}}
            ListboxProps={
              ListboxProps||{
                style: {
                  paddingTop: '10px',
                  border: '1px solid #e2e2e2'
                }
              }
            }
            renderInput={(params) => <TextField {...params} InputLabelProps={{shrink: true}} label={label} fullWidth error={isError} />}
            onChange={(_,value) => {
              if(value.some((item) => item.value==='todos')) {
                field.onChange([{label: 'Todos',value: 'todos'}]);
              } else {
                field.onChange(value)
              }
            }
            }
            value={field.value||[]}
            defaultValue={defaultValue}
          // : options.find((option) => option.value === field.value)
          />
        )}
      />
      <Box sx={{minHeight: '25px'}}>
        {isError&&(
          <FormHelperText sx={{marginLeft: '0px'}}>
            {externalError? externalError?.message:control._formState.errors[name]?.message}
          </FormHelperText>
        )}
      </Box>
    </FormControl>
  );
}
