import React from 'react';
import {FormControlLabel, Switch} from '@mui/material';
import {Controller} from 'react-hook-form';

export default function InputSwitch({name,control,label, action = 'cadastrar'}) {
    return (
        <FormControlLabel
            value="top"
            control={
                <Controller
                    control={control}
                    name={name}
                    disabled={action==='visualizar'}
                    render={({field: {value,...field}}) => (
                        <Switch checked={value} {...field} />
                    )}
                />
            }
            label={label}
            labelPlacement="top"
        />
    );
}
