import {Box, Button, Modal, Typography} from "@mui/material";

const styleDelete = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 300,
    bgcolor: 'background.default',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

export default function ModalDynamic({openModal, setOpenModal, functionModal, title}) {
    return (
        <Modal
            open={openModal}
            sx={{zIndex: 9999}}
            onClose={() => setOpenModal(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={styleDelete}>
                <Typography id="modal-modal-title" variant="h3" component="h2" sx={{marginBottom: '20px'}}>
                    {title}
                </Typography>
                <Box sx={{display: 'flex',justifyContent: 'space-evenly'}}>
                    <Button variant="contained" color="error" onClick={() => {setOpenModal(false)}}>Cancelar</Button>
                    <Button variant="contained" color="primary" onClick={() => {setOpenModal(false); functionModal()}}>Confirmar</Button>
                </Box>
            </Box>
        </Modal>
    )
}