import * as Icons from '@mui/icons-material';
import React,{useState} from 'react';
import {
  Table,
  TableBody,
  TableContainer,
  TableCell,
  TableRow,
  Paper,
  TablePagination,
  Grid,
  useTheme,
  TextField,
  Button,
  Menu,
  MenuItem,
  Avatar,
  keyframes,
  Pagination,
  Modal,
  IconButton,
  Collapse,
  FormControlLabel,
  Switch,
  Autocomplete,
  Typography,
  Tooltip,
} from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import MainCard from 'ui-component/cards/MainCard';
import {useEffect} from 'react';
import {useNavigate,useSearchParams} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {showSnackbar} from 'store/SnackBar/actions';
import {useLocation} from 'react-router';
import {Box,useMediaQuery} from '@mui/system';
import {ConfigMenuContext,useConfigMenu} from 'contexts/ConfigMenu';
import {useContext} from 'react';
import Div from 'ui-component/layouts/components/Div';
import {DatePicker,DateTimePicker} from '@mui/x-date-pickers';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import 'dayjs/locale/pt';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import axiosConfig from 'config/AxiosConfig';
import TableHeadComponentWithOrder from 'components/TableHeadComponentWithOrder';
import ModalDynamic from 'components/ModalDynamic';
import DeleteModal from 'components/DeleteModal';
import Teste from 'views/pages/administrativo/equipes/Teste';
import InputAutocomplete from 'components/InputAutocomplete';
import {useForm} from 'react-hook-form';
import InputTextField from 'components/InputTextField';
import InputSwitch from 'components/InputSwitch';
import InputAutocompleteAdd from 'components/InpuiAutoCompleteAdd';
import InputDatePickerTime from 'components/InputDatePickerTime';
import ConfirmModal from 'components/ConfirmModal';
var weekOfYear=require("dayjs/plugin/weekOfYear");
var weekYear=require("dayjs/plugin/weekYear");

dayjs.extend(weekYear)
dayjs.extend(weekOfYear)

const locale='pt';

const myEffectIn=keyframes`
  0% {
    opacity: 0;
    height: 0px;
  },
  50% {
    opacity: 0;
  },
  100% {
    opacity: 1;
    height: 127px;
  }
`;

const myEffectOut=keyframes`
  0% {
    opacity: 1;
    height: 127px;
  },
  50% {
    opacity: 0;
  },
  100% {
    opacity: 0;
    height: 0px;
  }
`;

function Icon({iconName,color='gray'}) {
  const IconComponent=Icons[iconName];
  if(!IconComponent) {
    // Ícone não encontrado, você pode renderizar um ícone padrão ou tratar de outra forma
    return null;
  }
  return <IconComponent sx={{color: `${color} !important`}} />;
}

const semana=(date) => {
  // date = '2021-10-01'
  let dateSplit='2021-10-01';
  if(!date) return '';
  if(date?.includes('T')) {
    const dateSplited=date.split('T');
    dateSplit=dateSplited[0].split('-');
  } else if(date?.split(' ')?.length>0) {
    dateSplit=date.split(' ')[0].split('-');
  } else {
    dateSplit=date?.split('-');
  }
  const ano=dateSplit[0];
  const mes=dateSplit[1];
  const dia=dateSplit[2];
  return dayjs(`${ano}-${mes}-${dia}`).week();
}

const mesAno=(date) => {
  // date = '2021-10-01'
  let dateSplit='2021-10-01';
  if(!date) return '';
  if(date?.includes('T')) {
    const dateSplited=date.split('T');
    dateSplit=dateSplited[0].split('-');
  } else if(date?.split(' ')?.length>0) {
    dateSplit=date.split(' ')[0].split('-');
  } else {
    dateSplit=date?.split('-');
  }
  const ano=dateSplit[0];
  const mes=dateSplit[1];
  return `${mes}/${ano}`;
}

const mesAbrevAno=(date) => {
  // date = '2021-10-01'
  let dateSplit='2021-10-01';
  if(!date) return '';
  if(date?.includes('T')) {
    const dateSplited=date.split('T');
    dateSplit=dateSplited[0].split('-');
  } else if(date?.split(' ')?.length>0) {
    dateSplit=date.split(' ')[0].split('-');
  } else {
    dateSplit=date?.split('-');
  }
  const ano=dateSplit[0];
  const mes=dateSplit[1];

  const mesesAbreviados={
    '01': 'Jan',
    '02': 'Fev',
    '03': 'Mar',
    '04': 'Abr',
    '05': 'Mai',
    '06': 'Jun',
    '07': 'Jul',
    '08': 'Ago',
    '09': 'Set',
    '10': 'Out',
    '11': 'Nov',
    '12': 'Dez',
  }

  return `${mesesAbreviados[mes]}/${ano}`;
}

const dataBr=(date) => {
  // date = '2021-10-01' || 2024-06-03T18:11:03.000000Z
  let dateSplit='2021-10-01';
  if(!date) return '';
  if(date?.includes('T')) {
    const dateSplited=date.split('T');
    dateSplit=dateSplited[0].split('-');
  } else if(date?.split(' ')?.length>0) {
    dateSplit=date.split(' ')[0].split('-');
  } else {
    dateSplit=date?.split('-');
  }
  const ano=dateSplit[0];
  const mes=dateSplit[1];
  const dia=dateSplit[2];

  return `${dia}/${mes}/${ano}`;
}

const simNao=(value) => {
  // value = 'Y' ou 1
  if(typeof value==='string') {
    return value==='Y'? 'Sim':'Não';
  } else {
    return value===1? 'Sim':'Não';
  }
}

const widthToGrid=(width) => {
  switch(width) {
    case '100%':
      return 12;
    case '50%':
      return 6;
    case '33%':
      return 4;
    case '25%':
      return 3;
    case '20%':
      return 2;
    case '15%':
      return 2;
    case '12.5%':
      return 1;
    default:
      return 12;
  }
};

const functionsName={
  'mesAno': mesAno,
  'dataBr': dataBr,
  'simNao': simNao,
  'semana': semana,
  'novaLinha': () => {},
  'mesAbrevAno': mesAbrevAno,
  'valorBr': (value) => {
    if(typeof value==='string') {
      if(value.includes('.')) {
        if(value.length>7) {
          return (Number(value)/100).toLocaleString('pt-BR',{style: 'currency',currency: 'BRL'}).replace('R$','')
        }
        return Number(value).toLocaleString('pt-BR',{style: 'currency',currency: 'BRL'}).replace('R$','')
      }
    }
    Number(value).toLocaleString('pt-BR',{style: 'currency',currency: 'BRL'}).replace('R$','')
  },
  'valorBrReal': (value) => {
    if(typeof value==='string') {
      if(value.includes('.')) {
        if(value.length>7) {
          return (Number(value)/100).toLocaleString('pt-BR',{style: 'currency',currency: 'BRL'})
        }
        return Number(value).toLocaleString('pt-BR',{style: 'currency',currency: 'BRL'})
      }
    }
    Number(value).toLocaleString('pt-BR',{style: 'currency',currency: 'BRL'})
  },
}

function getNestedValue(obj,path) {
  return path.split(/\.|\[|\]/).filter(Boolean).reduce((acc,part) => acc&&acc[part],obj);
}

// ------------------         COMPONENT USER ROW         ------------------ //

const UserRow=({user,index,columns,handleClick,opcoesPrograma,anchorEl,handleMenuItem,isFirstName,visualizarListar,userMenu,handleClose,pathname,setLoading,applyFilters}) => {
  const [openCollapse,setOpenCollapse]=useState(false);
  const [columnCollapse,setColumnCollapse]=useState(null);
  const [dataOkrs,setDataOkrs]=useState([]);
  const [openModal,setOpenModal]=useState(false);
  const [openModalStatus,setOpenModalStatus]=useState(false);
  const [options,setOptions]=useState([]);
  const [motivo,setMotivo]=useState('');
  const [openConfirm,setOpenConfirm]=useState(false);
  const theme=useTheme();
  const sm=useMediaQuery(theme.breakpoints.down('sm'));

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: {errors}
  }=useForm({
    defaultValues: {
      data: dayjs(),
      novo_andamento: false,
      tipo_status_id: pathname.includes('juridico')? user?.contratos_andamento?.tipo_status_id||'':user?.propostas_andamento_last?.tipo_status_id||'',
      tipo_andamento_id: pathname.includes('juridico')? user?.contratos_andamento?.tipo_andamento_id||'':user?.propostas_andamento_last?.tipo_andamento_id||'',
      observacao: pathname.includes('juridico')? user?.contratos_andamento?.observacao||'':user?.propostas_andamento_last?.observacao||'',
      descricao: pathname.includes('juridico')? user?.contratos_andamento?.descricao||'':user?.propostas_andamento_last?.descricao||'',
      propostas_id: user.id,
    },
  });

  const style={
    position: 'absolute',
    right: '0',
    width: '100px',
    height: '100%',
    overflowY: 'scroll',
    border: '2px solid #000',
    backgroundColor: theme.palette.background.default,
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

  const fetchOptions=async () => {
    setLoading(true);
    await axiosConfig.get(`/tipo/status/listar?where[rota]=${pathname.includes('juridico')? 'juridico/contratos':'comercial/propostas'}&limit=50`).then((response) => {
      const data=response.data.data.objetos.map((item) => {
        return {value: item.id,label: item.tipo}
      });
      setOptions(data);
    }).catch((error) => {
      console.log(error);
    });
    await axiosConfig.get('/tipo/andamento/listar?where[rota]=comercial/propostas_andamento&limit=50').then((response) => {
      const data=response.data.data.objetos.map((item) => {
        return {value: item.id,label: item.tipo}
      });
      setMotivo(data);
    }).catch((error) => {
      console.log(error);
    });
    setLoading(false);
  }

  const handleCloseModal=() => {
    setOpenModalStatus(false);
    setValue('tipo_status_id','');
    setValue('tipo_andamento_id','');
    setValue('observacao','');
    setValue('descricao','');
    setValue('data',dayjs());
  }

  const onSubmit=async (data) => {
    const dataNome=JSON.stringify(data.data);
    const dataDia=dataNome.split('T')[0].replaceAll('"','');
    const dataHora=dataNome.split('T')[1].split('.')[0];
    setLoading(true);
    try {

      if(pathname.includes('juridico')) {
        await axiosConfig.put(`juridico/contratos/editar`,{
          data: [{
            id: data.propostas_id,
            tipo_status_id: data.tipo_status_id,
            contratos_andamento: data?.novo_andamento? [
              {
                tipo_status_id: data.tipo_status_id,
                tipo_andamento_id: data.tipo_andamento_id,
                observacao: data.novo_andamento? data.observacao:undefined,
                data: data.novo_andamento? dataDia+' '+dataHora:undefined,
                descricao: data.novo_andamento? data.descricao:undefined,
              }
            ]:undefined
          }]
        });

      } else {
        await axiosConfig.put(`comercial/propostas/editar`,{
          data: [{
            id: data.propostas_id,
            tipo_status_id: data.tipo_status_id,
            propostas_andamento: data?.novo_andamento? [
              {
                tipo_status_id: data.tipo_status_id,
                tipo_andamento_id: data.tipo_andamento_id,
                observacao: data.novo_andamento? data.observacao:undefined,
                data: data.novo_andamento? dataDia+' '+dataHora:undefined,
                descricao: data.novo_andamento? data.descricao:undefined,
              }
            ]:undefined
          }]
        });
      }
      applyFilters();
      handleCloseModal();
    } catch(e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const openModalConfirm=() => {
    setOpenConfirm(true);
  }

  const disabledButton=columnCollapse?.colunas.filter((coluna) => {
    return coluna?.td?.editavel==='Y'
  }).length>0;

  return (
    <>
      <TableRow sx={{'& > *': {borderBottom: 'unset'},backgroundColor: index%2===0? `${theme.palette.table.tableContraste} !important`:'',":hover": {'cursor': 'pointer'}}}>
        {
          columns.map((column,index) => {
            if(column.function&&column.function==='novaLinha') {
              if(column!==columnCollapse) {
                setColumnCollapse(column);
              }
              return (
                <TableCell key={`${column.value} = ${index}`}>
                  <IconButton onClick={() => setOpenCollapse(!openCollapse)}>
                    {
                      openCollapse? <Icon iconName={column?.icone_fechado} />:<Icon iconName={column?.icone_aberto} />
                    }
                  </IconButton>
                </TableCell>
              )
            }
            if(column.isNested) {
              if(column.function) {
                return (
                  <TableCell key={`${column.value} = ${index}`} align={column.align} onClick={() => {
                    if(visualizarListar) {
                      navigate(`/${rotaBase}/visualizar/${user.id}`)
                    }
                  }}>
                    {functionsName[column.function](getNestedValue(user,column.nested))}
                  </TableCell>
                )
              }
              if(column.value.toLowerCase().includes('tipo')) {
                const cor=column.nested.replace('.tipo','.cor')
                const iconName=column.nested.replace('.tipo','.icone')
                const id=column.nested.replace('.tipo','.id')
                let alignJustify='center'
                if(column.align==='right') {
                  alignJustify='end'
                } else if(column.align==='left') {
                  alignJustify='start'
                }
                return (
                  <TableCell key={`${column.value} = ${index}`} align={column.align} onClick={() => {
                    if(visualizarListar) {
                      navigate(`/${rotaBase}/visualizar/${user.id}`)
                    }
                  }}>
                    <Div sx={{display: 'flex',gap: 0.5,alignItems: 'center',justifyContent: alignJustify}}>
                      <Typography onClick={() => {
                        if(options.length===0) {
                          fetchOptions();
                        }
                        setOpenModalStatus(true); setValue('tipo_status_id',getNestedValue(user,id));
                      }}>
                        {getNestedValue(user,column.nested)}
                      </Typography>
                      <Tooltip title='Alterar status'>
                        <IconButton sx={{':hover': {background: 'none'}}} onClick={() => {
                          if(options.length===0) {
                            fetchOptions();
                          }
                          setOpenModalStatus(true); setValue('tipo_status_id',getNestedValue(user,id));
                        }}>
                          <Icon iconName={getNestedValue(user,iconName)} color={getNestedValue(user,cor)} />
                        </IconButton>
                      </Tooltip>
                    </Div>
                  </TableCell>
                )
              }
              return (
                <TableCell key={`${column.value} = ${index}`} align={column.align} onClick={() => {
                  if(visualizarListar) {
                    navigate(`/${rotaBase}/visualizar/${user.id}`)
                  }
                }}>{
                    getNestedValue(user,column.nested)
                  }</TableCell>
              )
            }
            if(column.value.includes('link')) {
              return (
                <TableCell key={`${column.value} = ${index}`} align={column.align} onClick={() => {
                  if(visualizarListar) {
                    navigate(`/${rotaBase}/visualizar/${user.id}`)
                  }
                }}>
                  <Div>
                    <a href={user[column.value]} target="_blank" rel="noreferrer">
                      {user[column.value].length>30? `${user[column.value].substring(0,30)}...`:user[column.value]}
                    </a>
                  </Div>
                </TableCell>
              )
            }
            if(column.function) {
              return (
                <TableCell key={`${column.value} = ${index}`} align={column.align} onClick={() => {
                  if(visualizarListar) {
                    navigate(`/${rotaBase}/visualizar/${user.id}`)
                  }
                }}>
                  {functionsName[column.function](user[column.value])}
                </TableCell>
              )
            }
            if(column.value==='action'&&opcoesPrograma.length>0) {
              return <TableCell key={`${column.value} = ${index}`} sx={{display: 'flex',justifyContent: isFirstName? 'start':'center'}}>
                <Avatar
                  variant="rounded"
                  sx={{
                    ...theme.typography.commonAvatar,
                    ...theme.typography.mediumAvatar,
                    color: theme.palette.reso.white,
                    zIndex: 1,
                    borderRadius: '50%',
                  }}
                  aria-controls="menu-earning-card"
                  aria-haspopup="true"
                  onClick={(event) => handleClick(event,user)}
                >
                  <MoreHorizIcon fontSize="inherit" />
                </Avatar>
                <Menu
                  id="menu-earning-card"
                  anchorEl={anchorEl}
                  // keepMounted
                  open={userMenu? userMenu.id===user.id:false}
                  onClose={handleClose}
                  variant="selectedMenu"
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                  }}
                >
                  {
                    opcoesPrograma.map((opcao,index) => (
                      <MenuItem key={`${opcao.chave} = ${index}`} onClick={() => handleMenuItem(opcao)}>
                        <Icon iconName={opcao.icone} />
                        <Box sx={{marginLeft: 1}}>
                          {opcao.nome}
                        </Box>
                      </MenuItem>
                    ))
                  }
                </Menu>
              </TableCell>
            }
            return (
              <TableCell key={`${column.value} = ${index}`} align={column.align} onClick={() => {
                if(visualizarListar) {
                  navigate(`/${rotaBase}/visualizar/${user.id}`)
                }
              }}>
                {user[column.value]}
              </TableCell>
            )
          })
        }
      </TableRow>
      <TableRow sx={{'& > *': {borderBottom: 'unset'},backgroundColor: index%2===0? `${theme.palette.table.tableContraste} !important`:'',":hover": {'cursor': 'pointer'}}}>
        <TableCell style={{padding: 0}} colSpan={12}>
          <Collapse in={openCollapse} timeout="auto" unmountOnExit>
            <Grid container padding={2} spacing={2}>
              {
                columnCollapse?.colunas?.map((coluna) => {
                  const campo=coluna?.td;
                  const header=coluna?.th
                  if(campo?.function==='json') {
                    const json=JSON.parse(user[campo.value]);
                    const keys=Object.keys(json);
                    if(dataOkrs.length===0) {
                      setDataOkrs({
                        okrs: json
                      });
                    }
                    if(campo?.tipo==='switch') {
                      return keys.map((key) => {
                        return (
                          <Grid key={key} item xs={6} sm={4} md={3} lg={2} xl={2}>
                            <FormControlLabel
                              value='top'
                              label={key}
                              labelPlacement='top'
                              control={
                                <Switch checked={dataOkrs?.okrs?.[key]||false} onChange={() => {
                                  console.log(dataOkrs);
                                  setDataOkrs({
                                    okrs: {
                                      ...dataOkrs.okrs,
                                      [key]: !dataOkrs.okrs[key]
                                    }
                                  });
                                }} />
                              }
                            />
                          </Grid>
                        )
                      })
                    }
                  } else {
                    if(campo.editavel==='Y') {
                      if(campo.tipo==='text') {
                        return (
                          <Grid key={campo.value} item xs={6} sm={4} md={campo.width==='100%'? 12:4} lg={campo.width==='100%'? 12:4} xl={campo.width==='100%'? 12:4}>
                            <TextField
                              label={campo.label}
                              disabled={campo.editavel==='N'}
                              variant='outlined'
                              fullWidth
                              value={getNestedValue(user,campo.value)}
                              onChange={(e) => {
                                setDataOkrs({...dataOkrs,[campo.value]: e.target.value});
                              }}
                            />
                          </Grid>
                        )
                      }
                    } else {

                      if(campo.function) {
                        return (
                          <>
                            <Grid key={campo.value} item xs={6} sm={4} md={widthToGrid(campo.width)} lg={widthToGrid(campo.width)} xl={widthToGrid(campo.width)}>
                              <Typography sx={{marginBottom: '10px',fontWeight: '600'}}>
                                {header.label}:
                              </Typography>
                              <Typography>
                                {functionsName[campo.function](getNestedValue(user,campo.value))}
                              </Typography>
                            </Grid>
                          </>
                        )
                      }

                      return (
                        <>
                          <Grid key={campo.value} item xs={6} sm={4} md={widthToGrid(campo.width)} lg={widthToGrid(campo.width)} xl={widthToGrid(campo.width)}>
                            <Typography sx={{marginBottom: '10px',fontWeight: '600'}}>
                              {header.label}:
                            </Typography>
                            <Typography>
                              {getNestedValue(user,campo.value)}
                            </Typography>
                          </Grid>
                        </>
                      )
                    }
                  }
                })
              }
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                {
                  disabledButton&&<Button variant="contained" onClick={() => setOpenModal(true)}>Salvar</Button>
                }
              </Grid>
              <ModalDynamic openModal={openModal} setOpenModal={setOpenModal} functionModal={async () => {
                setLoading(true);
                try {
                  await axiosConfig.put(pathname.replace('listar','editar2'),{
                    data: [{
                      id: user.id,
                      ...dataOkrs,
                    }]
                  });
                } catch(e) {
                  console.log(e);
                } finally {
                  setLoading(false);
                }
              }} title='Deseja fazer a alteração?' />
            </Grid>
          </Collapse>
        </TableCell>
      </TableRow>
      {
        openModalStatus&&(
          <Modal
            anchor="right"
            open={openModalStatus}
            container={() => document.getElementById('root-mui')}
            onClose={handleCloseModal}
            variant="temporary"
            elevation={16}
            timeout={200}
            PaperProps={{
              sx: {
                width: sm? '100%':'500px !important',
              }
            }}>
            <Box
              sx={{...style,width: sm? '100%':'500px !important'}}>
              <Grid container spacing={2} sx={{padding: 0,marginLeft: 0,marginTop: '0px !important',width: '100%'}}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{paddingTop: `0 !important`,marginTop: '16px'}}>
                  <InputAutocomplete
                    options={options}
                    label='Status'
                    variant='outlined'
                    fullWidth
                    externalError={errors?.tipo_status_id}
                    rules={{required: 'Campo obrigatório'}}
                    control={control}
                    name='tipo_status_id'
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{paddingTop: `0 !important`,marginTop: '16px'}}>
                  <InputTextField
                    label='Observação'
                    variant='outlined'
                    multiline
                    rows={4}
                    fullWidth
                    externalError={errors?.observacao}
                    control={control}
                    name='observacao'
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{paddingTop: `0 !important`,marginTop: '0px',paddingLeft: '0px'}}>
                  <InputSwitch
                    label='Novo Andamento'
                    control={control}
                    name='novo_andamento'
                  />
                </Grid>
                {
                  watch('novo_andamento')&&(
                    <>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{paddingTop: `0 !important`,marginTop: '16px'}}>
                        <InputAutocompleteAdd
                          options={motivo}
                          label='Tipo de Andamento'
                          variant='outlined'
                          fullWidth
                          externalError={errors?.tipo_andamento_id}
                          rules={{required: 'Campo obrigatório'}}
                          control={control}
                          name='tipo_andamento_id'
                          StateProps={{tipo: '',rota: 'comercial/propostas_andamento'}}
                          renderComponents={[{name: 'tipo',label: 'Tipo de Andamento',type: 'text'}]}
                          rotaBase={'tipo/andamento'}
                          setOptions={setMotivo}
                          setValue={setValue}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{paddingTop: `0 !important`,marginTop: '16px'}}>
                        <InputDatePickerTime
                          name='data'
                          control={control}
                          label='Data e Hora'
                          rules={{required: 'Campo obrigatório'}}
                          externalError={errors?.data}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{paddingTop: `0 !important`,marginTop: '16px'}}>
                        <InputTextField
                          label='Descrição'
                          variant='outlined'
                          multiline
                          rows={4}
                          fullWidth
                          externalError={errors?.descricao}
                          control={control}
                          rules={{required: 'Campo obrigatório'}}
                          name='descricao'
                        />
                      </Grid>
                    </>
                  )
                }
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{paddingTop: `0 !important`,marginTop: '16px'}}>
                  <Button variant='contained' onClick={handleSubmit(openModalConfirm)}>Salvar</Button>
                </Grid>
              </Grid>
            </Box>
          </Modal>
        )
      }
      <ConfirmModal
        openModal={openConfirm}
        setOpenModal={setOpenConfirm}
        confirm={handleSubmit(onSubmit)}
      />
    </>
  );
};

// ------------------         COMPONENT USER TABLE        ------------------ //

const UserTable=({openModal,handleOpenFilterModal,isFirstName,openModalAnimation,responseMenu,columns,rotaBase,name,notMenu,visualizarListar,tipo,isNewRequest,rotaBaseNavigate}) => {
  const [anchorEl,setAnchorEl]=useState(null);
  const navigate=useNavigate();
  const [userMenu,setUserMenu]=useState(null);
  const theme=useTheme();
  const dispatch=useDispatch();
  const [users,setUsers]=useState([]);
  const [filtersInputs,setFiltersInputs]=useState([]);
  const [filters,setFilters]=useState({});
  const [page,setPage]=useState(1);
  const [rowsPerPage,setRowsPerPage]=useState(10);
  const [rowsPerPagePrev,setRowsPerPagePrev]=useState(10);
  const [pagePrev,setPagePrev]=useState(1);
  const [totalItems,setTotalItems]=useState(0);
  const [totalPages,setTotalPages]=useState(0);
  const [filteredUsers,setFilteredUsers]=useState(users);
  const [removeFilter,setRemoveFilter]=useState(false);
  const [opcoesPrograma,setOpcoesPrograma]=useState([]);
  const [loading,setLoading]=useState(false);
  const [openModalDelete,setOpenModalDelete]=useState(false);
  const [openModalConfirm,setOpenModalConfirm]=useState(false);
  const {response,user}=useContext(ConfigMenuContext);
  const location=useLocation();
  const {pathname}=location;
  const {language}=useConfigMenu();
  const {exibir,filtrar,limpar}=language.usuarios.table
  const [searchParams,setSearchParams]=useSearchParams({
    limit: 10,
    page: 1,
  });
  const limits=[10,50,100,500];

  const handleClick=(event,user) => {
    event.stopPropagation();
    setUserMenu(user);
    setAnchorEl(event.currentTarget);
  };

  const handleClose=() => {
    setAnchorEl(null);
    setUserMenu(null);
    setOpenModalDelete(false);
  };

  useEffect(() => {
    const limit=searchParams.get('limit');
    const page=searchParams.get('page');

    localStorage.setItem('redirect','');

    if(limit) {
      limits.forEach((value) => {
        if(value===parseInt(limit)) {
          setRowsPerPage(parseInt(limit));
        }
      });
    } else {
      setSearchParams({page: searchParams.get('page')||1,limit: 10});
    }
    if(page) {
      setPage(parseInt(page));
    } else {
      setSearchParams({page: 1,limit: searchParams.get('limit')||10});
    }
  },[searchParams]);

  const applyCondition = () => {
    const keys=window.location.pathname.split('/')
    const programa=keys[1]
    const modulo=keys[2]

    let condicao=responseMenu.menu[programa].programas[modulo].tela_listar.condicao;

    if(!condicao) condicao=[]

    let finalPath='';
    const keysCondicao=Object.keys(condicao);

    keysCondicao.forEach(async (key) => {
      if(key.includes('where')) {
        let whereObj=condicao[key];
        let lastKey=key; // Inicializa com a chave atual
        let path=[key]; // Cria um array para armazenar o caminho das chaves

        // Percorre as chaves do objeto encontrado em 'where'
        while(typeof whereObj==='object'&&whereObj!==null) {
          const innerKeys=Object.keys(whereObj);

          if(innerKeys.length>0) {
            if(innerKeys[0]!='like') {
              lastKey=innerKeys[0]; // Atualiza a chave para o próximo nível
              path.push(lastKey); // Adiciona a chave ao caminho
              whereObj=whereObj[lastKey]; // Move para o próximo nível do objeto
            } else {
              path.push(innerKeys[0]); // Adiciona a chave ao caminho
              whereObj=whereObj[innerKeys[0]]; // Move para o próximo nível do objeto
            }
          } else {
            break;
          }
        }

        // Formata o array 'path' para o formato desejado
        finalPath=path.map((p,index) => (index===0? p:`[${p}]`)).join('');
      }
    });
    
    const path = getNestedValue(condicao, finalPath);

    return path ? `&${finalPath}=${path}` : '';
  }

  useEffect(() => {
    const fetchProgramas=async () => {
      setLoading(true);
      try {
        if(!notMenu) {
          const opcoes=response.menu[pathname.split('/')[1]].programas[pathname.split('/')[2]].opcoes;
          if(opcoes) {
            const opcoesMap=Object.entries(opcoes).map(([chave,valor]) => {
              return {chave,...valor};
            })
            setOpcoesPrograma(opcoesMap);
          } else {
            setOpcoesPrograma([]);
          }
        } else {
          setOpcoesPrograma([{
            chave: 'editar',
            nome: 'Editar',
            icone: 'Edit'
          },{
            chave: 'excluir',
            nome: 'Excluir',
            icone: 'Delete'
          }]);
        }
        const method=notMenu? 'get':'post';
        const listar=localStorage.getItem('listar')
        let responseData={};
        const finalPath = applyCondition();
        if(method==='get'||isNewRequest) {
          responseData=await axiosConfig.get(`/${rotaBase}/listar?page=1&limit=10${finalPath}`)
        } else {
          responseData=await axiosConfig.post(`/${rotaBase}/listar?page=1&limit=10${finalPath}`,{});
        }
        const objetos=responseData.data.data.objetos;
        if(listar&&listar!==undefined) {
          const dataCadastrado=await axiosConfig[method](`/${rotaBase}/listar?page=${responseData.data.data.paginacao.lastPage}&limit=10${tipo==='J'||tipo==='F'? `&tipo=${tipo}`:''}`,{});
          objetos.unshift(dataCadastrado.data.data.objetos[dataCadastrado.data.data.objetos.length-1]);
          objetos.pop()
          localStorage.removeItem('listar');
        }
        setTotalItems(responseData.data.data.paginacao.total);
        setTotalPages(responseData.data.data.paginacao.lastPage);
        setUsers(responseData.data.data.objetos)
      } catch(error) {
        console.log(error);
        dispatch(showSnackbar(`${language.usuarios.table.erros.error.list} ${name}`,'error'));
      } finally {
        setLoading(false);
      }
    }
    const fetchFilters=async () => {
      try {
        setLoading(true);
        const keys=window.location.pathname.split('/')
        const programa=keys[1]
        const modulo=keys[2]
        const personalizacao=JSON.parse(user.personalizacao);

        if(personalizacao?.menu&&personalizacao?.menu[programa]&&personalizacao?.menu[programa][modulo]&&personalizacao?.menu[programa][modulo]?.listar&&personalizacao?.menu[programa][modulo]?.listar?.exibir) {
          setRowsPerPage(personalizacao.menu[programa][modulo].listar.exibir);
          setRowsPerPagePrev(personalizacao.menu[programa][modulo].listar.exibir);
        }

        if(!responseMenu.menu||!responseMenu.menu[programa]||!responseMenu.menu[programa].programas[modulo]||!responseMenu.menu[programa].programas[modulo]?.tela_listar?.filtros) return
        let filtros=responseMenu.menu[programa].programas[modulo].tela_listar.filtros;
        if(!filtros) filtros=[]
        filtros.forEach((filtro) => {
          const keys=Object.keys(filtro);

          keys.forEach(async (key) => {
            if(key.includes('where')) {
              let whereObj=filtro[key];
              let lastKey=key; // Inicializa com a chave atual
              let path=[key]; // Cria um array para armazenar o caminho das chaves

              // Percorre as chaves do objeto encontrado em 'where'
              while(typeof whereObj==='object'&&whereObj!==null) {
                const innerKeys=Object.keys(whereObj);

                if(innerKeys.length>0) {
                  if(innerKeys[0]!='like') {
                    lastKey=innerKeys[0]; // Atualiza a chave para o próximo nível
                    path.push(lastKey); // Adiciona a chave ao caminho
                    whereObj=whereObj[lastKey]; // Move para o próximo nível do objeto
                  } else {
                    path.push(innerKeys[0]); // Adiciona a chave ao caminho
                    whereObj=whereObj[innerKeys[0]]; // Move para o próximo nível do objeto
                  }
                } else {
                  break;
                }
              }

              // Formata o array 'path' para o formato desejado
              const finalPath=path.map((p,index) => (index===0? p:`[${p}]`)).join('');
              filtro.nomeCampo=finalPath;

              if(filtro.tipoCampo==='data') {
                if(filtro.classe) {
                  if(typeof filtro.classe==='string') {
                    filtro.classe=filtro.classe.split(',');
                  }
                }
              }

              if(filtro.tipoCampo==='select'||filtro.tipoCampo==='multiselect') {
                const type=getNestedValue(filtro,finalPath);
                filtro.type=type;
                if(filtro.endpoint) {
                  try {
                    const response=await axiosConfig.get(filtro.endpoint);
                    const data=response.data.data.objetos.map((item) => {
                      return {value: item[filtro.values[0]],label: item[filtro.values[1]]}
                    })
                    filtro.data=data;
                  } catch(e) {
                    console.log(e);
                    dispatch(showSnackbar(`${language.usuarios.table.erros.error.filtros}`,'error'));
                  }
                }
              }

              setFilters((prevFilters) => ({
                ...prevFilters,
                [filtro.nomeCampo]: '',
              }));
            }
          });
        });
        setFiltersInputs(filtros);
      } catch(e) {
        console.log(e);
        dispatch(showSnackbar(`${language.usuarios.table.erros.error.filtros}`,'error'));
      } finally {
        setLoading(false);
      }
    }

    fetchFilters();
    fetchProgramas();
  },[])

  useEffect(() => {
    if(page!==pagePrev||rowsPerPage!==rowsPerPagePrev||removeFilter) {
      setRemoveFilter(false);
      applyFilters();
    }
  },[page,rowsPerPage,removeFilter]);

  const applyFilters=async (lastPageApply,filtersOrder) => {
    setLoading(true);

    let filtrosQuery='';

    if(filtersOrder) {
      Object.keys(filtersOrder).map((key) => {
        if(filtersOrder[key]!=='') {
          if(key.includes('orde')) {
            return filtrosQuery+=`&${key}=${filtersOrder[key]}`;
          }
        }
      });
    }

    Object.keys(filters).map((key) => {
      if(filters[key]!=='') {
        if(key.includes('orde')) {
          if(filtrosQuery.includes(`&${key}=${filters[key]}`)) return;
          return filtrosQuery+=`&${key}=${filters[key]}`;
        } else {
          filtersInputs.map((input) => {
            if(input.nomeCampo===key) {
              if(input.tipoCampo==='data') {
                filtrosQuery+=`&${input.nomeCampo}=${dayjs(filters[key]).format('YYYY-MM-DD')}`;
              } else if(input.tipoCampo==='multiselect') {
                if(filters[key].length>0) {
                  filters[key].map((item,index) => {
                    if(input.type) {
                      filtrosQuery+=`&${input.nomeCampo.replace('valorSelecionado',item.value).replace('0',index)}=${input.type}`;
                    } else {
                      filtrosQuery+=`&${input.nomeCampo}=${item.value}`;
                    }
                  });
                }
              } else {
                filtrosQuery+=`&${input.nomeCampo}=${filters[key]}`;
              }
            }
          }
          );
        }
      }
    });
    // const keys=window.location.pathname.split('/');
    // const objDataLimit={
    //   modulo: keys[1],
    //   programa: keys[2],
    //   users_id: user.id,
    // };
    try {
      // await axiosConfig.post(`/config/exibicao/${rowsPerPage}`,{
      //   data: objDataLimit
      // });

      const finalPath=applyCondition();

      const pageFilter=lastPageApply? lastPageApply:page;
      const response=await axiosConfig.get(`/${rotaBase}/listar?page=${pageFilter}&limit=${rowsPerPage}${filtrosQuery}${finalPath}`);
      const lastPage=response.data.data.paginacao.lastPage;
      if(Number(lastPage)<Number(pageFilter)) {
        setPage(Number(lastPage));
        setPagePrev(Number(lastPage));
        setSearchParams({page: Number(lastPage),limit: searchParams.get('limit')});
        return applyFilters(lastPage);
      }
      setTimeout(() => {
        setFilteredUsers(response.data.data.objetos);
        setTotalItems(response.data.data.paginacao.total);
        setTotalPages(response.data.data.paginacao.lastPage);
        setPagePrev(pageFilter);
        setRowsPerPagePrev(rowsPerPage);
        setLoading(false);
      },50);
    } catch(error) {
      console.log(error);
      setTimeout(() => {
        dispatch(showSnackbar(`${language.usuarios.table.erros.error.filtros} ${name}`,'error'));
        setLoading(false);
      },50);
    }
  };

  useEffect(() => {
    setFilteredUsers(users);
  },[users]);

  const handleClearFilters=() => {
    setFilters({});
    setPage(1);
    setPagePrev(1);
    setRowsPerPage(10);
    setRowsPerPagePrev(10);
    setSearchParams({page: 1,limit: 10});
    setTimeout(() => {
      // Cria um novo objeto acumulando todos os filtros
      const updatedFilters=filtersInputs.reduce((acc,filtro) => {
        acc[filtro.nomeCampo]=''; // Define o valor vazio para cada filtro
        return acc;
      },{});

      Object.keys(filters).filter((key) => {
        if(key.includes('orde')) return updatedFilters[key]=filters[key];
      });

      // Faz uma única atualização de estado com todos os filtros
      setFilters(updatedFilters);
    },500);
    setRemoveFilter(true);
  };


  const handleChangePage=(event,newPage) => {
    setPage(newPage);
    setSearchParams({page: newPage,limit: searchParams.get('limit')});
  };

  const handleChangeRowsPerPage=(event) => {
    setSearchParams({page: 1,limit: event.target.value});
    setRowsPerPage(+event.target.value);
    setPage(1);
  };

  const handleMenuItem=async (opcao) => {
    switch(opcao.chave) {
      case 'editar':
        editUser();
        break;
      case 'excluir':
        setOpenModalDelete(true)
        break;
      case 'permissoes':
        if(rotaBaseNavigate) return navigate(`/${rotaBaseNavigate}/permissoes/${userMenu.id}`);
        navigate(`/${rotaBase}/permissoes/${userMenu.id}`);
        break;
      case 'visualizar':
        if(rotaBaseNavigate) return navigate(`/${rotaBaseNavigate}/visualizar/${userMenu.id}`);
        navigate(`/${rotaBase}/visualizar/${userMenu.id}`);
        break;
      case 'responder':
        if(rotaBaseNavigate) return navigate(`/${rotaBaseNavigate}/responder/${userMenu.id}`);
        navigate(`/${rotaBase}/responder/${userMenu.id}`);
        break;
      case 'clonar':
        setOpenModalConfirm(true);
        break;
      default:
        console.log('Opção não encontrada');
        break;
    }
  }

  const deleteUser=async () => {
    try {
      setLoading(true);
      await axiosConfig.delete(`/${rotaBase}/excluir`,{data: {data: [{id: userMenu.id}]}})
      let generoName='excluído';
      if(name[name.length-1]==='s') {
        if(name[name.length-2]==='a') {
          generoName=`excluídas`
        } else {
          generoName=`excluídos`
        }
      } else {
        if(name[name.length-1]==='a') {
          generoName=`excluída`
        } else {
          generoName=`excluído`
        }
      }
      dispatch(showSnackbar(`${name} ${generoName} com sucesso`,'success'));
      handleClose();
      await applyFilters();
    } catch {
      dispatch(showSnackbar(`${language.usuarios.table.erros.error.delete} ${name}`,'error'));
    } finally {
      setLoading(false);
    }
  }

  const cloneUser=async () => {
    try {
      setLoading(true);
      const servicos_h=userMenu?.servicos_h.map((servico) => {
        return {...servico,id: undefined,propostas_id: undefined}
      })
      const unidades_negocio_h={
        ...userMenu?.unidades_negocio_h,
        id: undefined,
        propostas_id: undefined,
        unidades_negocio: undefined
      }
      await axiosConfig.post(`/${rotaBase}/cadastrar`,{data: [{...userMenu,cadastros: undefined, id: undefined,cadastros_id: 1,clonada: 'Y',propostas_andamento_last: undefined,dados_financeiros: JSON.parse(userMenu?.dados_financeiros),servicos_h,unidades_negocio_h}]});
      let generoName='clonado';
      if(name[name.length-1]==='a') {
        generoName=`clonada`
      }
      dispatch(showSnackbar(`${name} ${generoName} com sucesso`,'success'));
      handleClose();
      await applyFilters();
    } catch {
      dispatch(showSnackbar(`Erro ao clonar ${name}`,'error'));
    } finally {
      setLoading(false);
    }
  }

  const editUser=() => {
    const page=searchParams.get('page');
    const limit=searchParams.get('limit');
    localStorage.setItem('redirect',`/${rotaBase}/listar?page=${page}&limit=${limit}`);
    if(rotaBase.split('/')[0]==='cargos') return navigate(`/cargo-salario/editar/${userMenu.id}`);
    if(rotaBaseNavigate) return navigate(`/${rotaBaseNavigate}/editar/${userMenu.id}`);
    if(notMenu&&rotaBase.split('/')[0]!=='cadastros') return navigate(`/${rotaBase.split('/')[0]}/editar/${userMenu.id}`);
    else return navigate(`/${rotaBase}/editar/${userMenu.id}`);
  };

  const filterModal=() => {
    return (
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale}>
        <Box sx={{animation: openModal&&!openModalAnimation? `${myEffectIn} 0.3s ease-in-out`:`${myEffectOut} 0.3s ease-in-out`}}>
          <Grid container spacing={2} sx={{padding: 2,marginLeft: 0,marginTop: '0px !important',width: '100%'}}>
            {
              filtersInputs.map((input,index) => {
                let numberInputs=0;
                if(filtersInputs.length>3) numberInputs=3;
                else numberInputs=filtersInputs.length;
                if(input.tipoCampo==='data') {
                  return (
                    <Grid key={`${input.nomeCampo} = ${index}`} item xs={12} sm={12} md={12/numberInputs} lg={12/numberInputs} xl={12/numberInputs} sx={{paddingTop: `0 !important`,marginTop: '16px'}}>
                      <DatePicker
                        sx={{width: '100%','.Mui-error': {border: '#000000 !important',color: '#697586 !important'},'.MuiOutlinedInput-notchedOutline': {borderColor: '#000000 !important'}}}
                        label={input.label}
                        views={input.classe}
                        // inputFormat="dd/MM/yyyy"
                        format={input.classe.length>=3? 'DD/MM/YYYY':'MM/YYYY'}
                        value={filters[input.nomeCampo]}
                        onChange={(e) => setFilters({...filters,[input.nomeCampo]: e})}
                        renderInput={(params) => <TextField {...params} error={false} InputLabelProps={{shrink: true}} fullWidth sx={{marginTop: '16px',height: '40px',paddingTop: `0 !important`,paddingLeft: `0 !important`}} />}
                      />
                    </Grid>
                  )
                } else if(input.tipoCampo==='dataHora') {
                  return (
                    <Grid key={`${input.nomeCampo} = ${index}`} item xs={12} sm={12} md={12/numberInputs} lg={12/numberInputs} xl={12/numberInputs} sx={{marginTop: '16px',paddingTop: `0 !important`}}>
                      <DateTimePicker
                        sx={{width: '100%'}}
                        label={input.label}
                        views={input.classe}
                        inputFormat="dd/MM/yyyy"
                        value={filters[input.nomeCampo]}
                        onChange={(e) => setFilters({...filters,[input.nomeCampo]: e})}
                        renderInput={(params) => <TextField {...params} InputLabelProps={{shrink: true}} fullWidth sx={{paddingTop: `0 !important`,paddingLeft: `0 !important`}} />}
                      />
                    </Grid>
                  )
                } else if(input.tipoCampo==='select') {
                  return (
                    <Grid key={`${input.nomeCampo} = ${index}`} item xs={12} sm={12} md={12/numberInputs} lg={12/numberInputs} xl={12/numberInputs} sx={{marginTop: '16px',paddingTop: `0 !important`}}>
                      <Autocomplete
                        options={input.data}
                        getOptionLabel={(option) => option.label}
                        sx={{width: '100%'}}
                        renderInput={(params) => <TextField {...params} label={input.label} InputLabelProps={{shrink: true}} variant="outlined" />}
                        onChange={(e,v) => setFilters({...filters,[input.nomeCampo]: v? v.value:''})}
                      />
                    </Grid>
                  )
                } else if(input.tipoCampo==='multiselect') {
                  return (
                    <Grid key={`${input.nomeCampo} = ${index}`} item xs={12} sm={12} md={12/numberInputs} lg={12/numberInputs} xl={12/numberInputs} sx={{marginTop: '16px',paddingTop: `0 !important`}}>
                      <Autocomplete
                        options={input.data}
                        getOptionLabel={(option) => option.label}
                        multiple
                        sx={{width: '100%'}}
                        renderInput={(params) => <TextField {...params} label={input.label} InputLabelProps={{shrink: true}} variant="outlined" />}
                        onChange={(e,v) => setFilters({...filters,[input.nomeCampo]: v? v:''})}
                      />
                    </Grid>
                  )
                } else
                  return (
                    <Grid item xs={12} sm={12} md={12/numberInputs} lg={12/numberInputs} xl={12/numberInputs} key={`${input.nomeCampo} = ${index}`} sx={{marginTop: '16px',paddingTop: `0 !important`}}>
                      <TextField
                        sx={{paddingTop: `0 !important`,paddingLeft: `0 !important`,'.MuiInputBase-root': {height: '51px'}}}
                        label={input.label}
                        InputLabelProps={{shrink: true}}
                        variant="outlined"
                        size="small"
                        value={filters[input.nomeCampo]}
                        onChange={(e) => setFilters({...filters,[input.nomeCampo]: e.target.value})}
                        fullWidth
                        type={input.tipoCampo}
                      />
                    </Grid>
                  )
              })
            }
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Div sx={{display: 'flex',gap: 2}}>
                <Button
                  variant="contained"
                  onClick={() => applyFilters()}
                >
                  {filtrar}
                </Button>
                <Button
                  variant="contained"
                  onClick={handleClearFilters}
                >
                  {limpar}
                </Button>
              </Div>
            </Grid>
          </Grid>
        </Box>
      </LocalizationProvider>
    );
  }

  const sxMainCard=rotaBase.includes('equipe')? {
    backgroundColor: 'transparent',
    borderColor: 'black',
    boxShadow: 'none',
    ':hover': {
      boxShadow: 'none',
    }
  }:{}

  return (
    <>
      <MainCard title={name} elevation={3} handleOpenFilterModal={handleOpenFilterModal} buttonFilter={filtersInputs.length>0} openModal={openModal} filterContext={filterModal} sx={{...sxMainCard}}>
        {
          rotaBase.includes('equipe')? (<Teste />)
            :(
              <Paper sx={{width: '100%',overflow: 'hidden',height: '100%'}}>
                <Box sx={{display: 'flex',alignItems: 'center',justifyContent: 'space-between'}}>
                  <TablePagination
                    rowsPerPageOptions={[10,50,100,500]}
                    component="div"
                    labelRowsPerPage={exibir}
                    labelDisplayedRows={() => ''}
                    count={totalItems}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    nextIconButtonProps={{style: {display: "none"}}}
                    backIconButtonProps={{style: {display: "none"}}}
                    sx={{
                      '& .MuiSelect-select': {
                        backgroundColor: `${theme.palette.background.default} !important`
                      }
                    }}
                  />
                  <Pagination page={page} onChange={handleChangePage} count={totalPages} variant="outlined" shape="rounded" />
                </Box>
                {
                  rotaBase.includes('colaboradores')? (
                    <table>
                      <thead style={{display: 'table',tableLayout: 'fixed',marginBottom: '10px'}}>
                        <tr style={{position: 'relative',left: '-2px',width: 'calc(100% + 4px)',tableLayout: 'fixed',display: 'table'}}>
                          <th style={{width: '10px'}}></th>
                          <th style={{width: '35%',textAlign: 'left',fontWeight: '500'}}>Colaborador</th>
                          <th style={{fontWeight: '500'}}>Departamento</th>
                          <th style={{fontWeight: '500'}}>Pessoa Gestora</th>
                          <th style={{fontWeight: '500'}}>Matrícula</th>
                          <th style={{fontWeight: '500'}}>Ações</th>
                        </tr>
                      </thead>
                      {filteredUsers.map((user,index) => (
                        <tbody key={`${user.id} = ${index}`} style={{display: 'table',tableLayout: 'fixed',marginBottom: '10px'}}>
                          <div style={{boxShadow: 'rgba(0, 0, 0, 0.3) 0px 3px 3px 1px',borderLeft: '5px solid black',background: 'rgb(233, 233, 233)',height: '75px',textAlign: 'center',display: 'flex',alignItems: 'center',borderRadius: '5px',padding: 1}}>
                            <tr style={{position: 'relative',left: '-2px',width: 'calc(100% + 4px)',tableLayout: 'fixed',display: 'table'}}>
                              <td style={{width: '10px'}}></td>
                              <td style={{width: '35%',textAlign: 'left'}}>
                                <div style={{display: 'flex'}}>
                                  <Avatar src={user.cadastros.foto} sx={{width: 40,height: 40,marginRight: 2}} />
                                  <div style={{display: 'flex',flexDirection: 'column',justifyContent: 'center'}}>
                                    <Typography variant="body1" style={{fontWeight: '500',color: 'black',fontSize: '16px',lineHeight: '16px'}}>
                                      {user.cadastros.nome}
                                    </Typography>
                                    <Typography variant="caption" sx={{fontSize: '14px'}}>
                                      Executivo (a) de Atendimento II
                                    </Typography>
                                  </div>
                                </div>
                              </td>
                              <td style={{verticalAlign: 'middle'}}>Atendimento</td>
                              <td style={{verticalAlign: 'middle'}}>Erika Coradin</td>
                              <td style={{verticalAlign: 'middle'}}>{user.id}</td>
                              <td style={{verticalAlign: 'middle'}}>
                                <IconButton
                                  aria-label="more"
                                  aria-controls="long-menu"
                                  aria-haspopup="true"
                                  onClick={(e) => handleClick(e,user)}
                                >
                                  <Icons.MoreHoriz />
                                </IconButton>
                              </td>
                            </tr>
                          </div>
                        </tbody>
                      )
                      )}
                    </table>
                  ):(
                    <TableContainer sx={{maxHeight: '100%',borderRadius: 1}}>
                      <Table aria-label="customized collapsible table">
                        <TableHeadComponentWithOrder applyFilters={applyFilters} filters={filters} setFilters={setFilters} columns={columns} opcoesPrograma={opcoesPrograma} />
                        <TableBody>
                          {filteredUsers.map((user,index) => (
                            <>
                              <UserRow
                                index={index}
                                key={`${user.id} = ${index}:${index}`}
                                user={user}
                                columns={columns}
                                handleClick={handleClick}
                                opcoesPrograma={opcoesPrograma}
                                anchorEl={anchorEl}
                                handleMenuItem={handleMenuItem}
                                isFirstName={isFirstName}
                                visualizarListar={visualizarListar}
                                userMenu={userMenu}
                                handleClose={handleClose}
                                pathname={pathname}
                                setLoading={setLoading}
                                applyFilters={applyFilters}
                              />
                            </>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  )
                }
                <Box sx={{display: 'flex',justifyContent: 'end',marginTop: '25px'}}>
                  <Pagination page={page} onChange={handleChangePage} count={totalPages} variant="outlined" shape="rounded" />
                </Box>
              </Paper>
            )
        }
        <Modal open={loading}>
          <Box sx={{display: 'flex',justifyContent: 'center',alignItems: 'center',height: '100vh'}}>
            <div className="loader"></div>
          </Box>
        </Modal>
      </MainCard>
      <DeleteModal openModal={openModalDelete} setOpenModal={setOpenModalDelete} remove={deleteUser} />
      <ConfirmModal openModal={openModalConfirm} setOpenModal={setOpenModalConfirm} confirm={cloneUser} />
    </>
  );
};

export default function Listar({columns,isFirstName=false,rotaBase,name,notMenu=false,visualizarListar=true,filter,tipo=undefined,isNewRequest=false,rotaBaseNavigate=undefined}) {
  const [filterModalOpen,setFilterModalOpen]=useState(false);
  const [openModalAnimation,setOpenModalAnimation]=useState(false);
  const {response,loading}=useContext(ConfigMenuContext);

  if(loading) return null;

  const handleOpenFilterModal=() => {
    if(filterModalOpen) {
      setOpenModalAnimation(true);
      setTimeout(() => {
        setFilterModalOpen(!filterModalOpen);
        setOpenModalAnimation(false);
      },300);
    } else {
      setFilterModalOpen(!filterModalOpen);
    }
  };


  return (
    <UserTable isNewRequest={isNewRequest} rotaBaseNavigate={rotaBaseNavigate} visualizarListar={visualizarListar} isFirstName={isFirstName} tipo={tipo} openModal={filterModalOpen} handleOpenFilterModal={handleOpenFilterModal} filter={filter} columns={columns} rotaBase={rotaBase} name={name} openModalAnimation={openModalAnimation} responseMenu={response} notMenu={notMenu} />
  );
}
