import {Add,CalendarMonth,Home,Remove,Tune} from "@mui/icons-material";
import {Avatar,Box,Card,CardHeader,Chip,Grid,IconButton,Paper,SpeedDial,SpeedDialAction,SpeedDialIcon,Table,TableBody,TableCell,TableContainer,TableHead,TableRow,Typography} from "@mui/material";
import {Gauge,LineChart,PieChart,gaugeClasses} from "@mui/x-charts";
import Div from "ui-component/layouts/components/Div";
import CardAnalytics from "./components/CardAnalytics";
import {MapContainer,Marker,Popup,TileLayer} from "react-leaflet";
import {useState} from "react";

function createData(name,calories,fat,carbs,protein) {
    return {name,calories,fat,carbs,protein};
}

const actions=[
    {icon: <Home />,name: 'Inicio'},
    {icon: <CalendarMonth />,name: 'Calendário'},
    {icon: <Tune />,name: 'Filtros'}
];

const rows=[
    createData('Frozen yoghurt',159,6.0,24,4.0),
    createData('Ice cream sandwich',237,9.0,37,4.3),
    createData('Eclair',262,16.0,24,6.0),
    createData('Cupcake',305,3.7,67,4.3),
    createData('Gingerbread',356,16.0,49,3.9),
];

export default function Dashboard() {
    const [width_1,setWidth_1]=useState(4)
    const [width_2,setWidth_2]=useState(4)
    const [animation_1,setAnimation_1]=useState(false)
    const [animation_2,setAnimation_2]=useState(false)

    const handleWidthWithAnimation=(width,setWidth,setAnimation,setAnimation_2) => {
        if(width===4) {
            setAnimation(true)
            setAnimation_2(false)
        }
        setTimeout(() => {
            if(width===12) {
                setWidth(4)
                setTimeout(() => {
                    setAnimation(false)
                    setAnimation_2(false)
                },330)
            } else {
                setWidth(12)
            }
        },80)
    };

    return (
        <Div sx={{borderRadius: '5px'}}>
            <Div>
                <SpeedDial
                    ariaLabel="SpeedDial"
                    sx={{position: 'fixed',bottom: 16,right: 30}}
                    icon={<SpeedDialIcon />}
                >
                    {actions.map((action) => (
                        <SpeedDialAction
                            key={action.name}
                            icon={action.icon}
                            tooltipTitle={action.name}
                        />
                    ))}
                </SpeedDial>
            </Div>
            <Div sx={{width: '100%',height: '88.7vh',}}>
                <Div sx={{width: '100%',height: '40%',borderRadius: '5px',backgroundImage: 'url(https://precision.nyc3.cdn.digitaloceanspaces.com/public/img/dashboard/155/cover/41170ff5-f89e-4afd-abc2-2f99358b2643.png)',backgroundSize: 'cover',backgroundPosition: 'center',position: 'relative'}}>
                    <Div sx={{background: 'linear-gradient(0deg,#000,rgba(0,0,0,.06666666666666667) 80%,transparent)',position: 'absolute',width: '100%',height: '100%'}} />
                    <Grid container sx={{height: '100%',padding: '35px'}}>
                        <Grid item sm={12} sx={{zIndex: '2'}}>
                            <Typography variant="h1" sx={{color: 'white',textShadow: '.2rem .2rem .2rem rgba(0, 0, 0, .5333333333333333)',fontSize: '15px'}}>GRUPO JAGUAR LAND ROVER . JLR . 24.25</Typography>
                        </Grid>
                        <Grid item sm={6} sx={{zIndex: '2'}}>
                            <Typography variant="h2" sx={{color: 'white',textShadow: '.2rem .2rem .2rem rgba(0, 0, 0, .5333333333333333)',fontSize: '34px'}}>Relatório de maio | JLR</Typography>
                        </Grid>
                        <Grid item sm={12} sx={{zIndex: '2'}}>
                            <Div sx={{display: 'flex',width: '205px',borderRadius: '5px',background: '#00c2a8',padding: '.4rem .6rem',alignItems: 'center'}} >
                                <CalendarMonth sx={{width: '20px',height: '20px',mr: '5px',color: 'white'}} />
                                <Typography variant="h1" sx={{color: 'white',textShadow: '.2rem .2rem .2rem rgba(0, 0, 0, .5333333333333333)',fontSize: '13px'}}>01/05/2024 - 31/05/2024</Typography>
                            </Div>
                        </Grid>
                    </Grid>
                </Div>
                <Div sx={{width: '100%',height: '60%',background: 'black'}}>
                    <Grid container sx={{height: '100%',padding: '70px',alignItems: 'center'}}>
                        <Grid item lg={2} xl={2.2} sx={{zIndex: '2'}}>
                            <CardAnalytics iconName={'Language'} value={'166'} label={'MEDIA OUTLET - TRADITIONAL MEDIA'} percentage={'-24% AVG.'} />
                        </Grid>
                        <Grid item lg={2} xl={2.2} sx={{zIndex: '2'}}>
                            <CardAnalytics iconName={'PermMedia'} value={'42'} label={'MEDIA OUTLET - DIGITAL MEDIA'} percentage={'2% AVG.'} />
                        </Grid>
                        <Grid item lg={2} xl={1.6} sx={{zIndex: '2'}}>
                            <CardAnalytics iconName={'Groups'} value={'10M'} label={'ABSOLUTE REACH'} percentage={'-34% AVG.'} info='Número de visualizações únicas dos resultados.' />
                        </Grid>
                        <Grid item lg={2} xl={2.2} sx={{zIndex: '2'}}>
                            <CardAnalytics iconName={'AttachMoney'} value={'R$ 803K'} label={'AVE'} percentage={'-21% AVG.'} info='Valor estimado da publicidade, para resultados de Tradicional Media esse valor é baseado na centimetragem da matéria.' />
                        </Grid>
                        <Grid item lg={3} xl={3.2} spacing={2} sx={{zIndex: '2'}}>
                            <Div sx={{display: 'flex',flexDirection: 'column',gap: 1.3}}>
                                <Typography variant="h4" sx={{color: '#00c2a8',textShadow: '.2rem .2rem .2rem rgba(0, 0, 0, .5333333333333333)',fontSize: '18px'}}>VISÃO GERAL</Typography>
                                <Typography variant="body" sx={{color: 'white',textShadow: '.2rem .2rem .2rem rgba(0, 0, 0, .5333333333333333)',fontSize: '15px'}}>Durante o mês de maio, foi observado resultados tanto nos veículos tradicionais quanto nas redes sociais, com maior destaque nos meios especializados em <span style={{color: '#00c2a8'}}>Notícias Gerais</span> e <span style={{color: '#00c2a8'}}>Automotivo.</span></Typography>
                                <Typography variant="body" sx={{color: 'white',textShadow: '.2rem .2rem .2rem rgba(0, 0, 0, .5333333333333333)',fontSize: '15px'}}>Dentre os principais assuntos, destacam-se os resultados que mencionam que <span style={{color: '#00c2a8'}}>Enzo Fittipaldi é o novo atleta iniciante da Jaguar.</span></Typography>
                            </Div>
                        </Grid>
                    </Grid>
                </Div>
            </Div>
            <Div sx={{width: '100%',height: '55vh',background: 'rgb(46,46,46)'}}>
                <Grid container sx={{height: '100%',padding: '70px',alignItems: 'center'}}>
                    <Grid item lg={9} xl={8.2} sx={{zIndex: '2'}}>
                        <Div sx={{width: '100%',height: '300px'}}>
                            <LineChart
                                xAxis={[{data: [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15],tick: {format: 'MMM'},}]}
                                series={[
                                    {
                                        data: [2,5.5,2,8.5,1.5,15,2,5.5,2,8.5,1.5,15,8.5,1.5,15],
                                        area: true,
                                    },
                                ]}
                            />
                        </Div>
                    </Grid>
                    <Grid item lg={3} xl={3.2} spacing={2} sx={{zIndex: '2'}}>
                        <Div sx={{display: 'flex',flexDirection: 'column',gap: 1.3}}>
                            <Typography variant="h4" sx={{color: 'white',textShadow: '.2rem .2rem .2rem rgba(0, 0, 0, .5333333333333333)',fontSize: '18px'}}><span style={{color: '#00c2a8'}}>FREQUÊNCIAS</span></Typography>
                            <Typography variant="body" sx={{color: 'white',textShadow: '.2rem .2rem .2rem rgba(0, 0, 0, .5333333333333333)',fontSize: '15px'}}>Ao longo de maio, observaram-se menções à JLR quase diariamente, com <span style={{color: '#00c2a8'}}>destaque para os dias 9 e 25</span>, quando a marca recebeu um aumento considerável em sua visibilidade, isso ocorreu <span style={{color: '#00c2a8'}}>especialmente após anunciar Enzo Fittipaldi nos testes de novatos da Fórmula E</span> e com os resultados que mencionavam a <span style={{color: '#00c2a8'}}>vitória de Mitch Evans em Xangai</span>, gerando diversas menções relacionadas à corrida.</Typography>
                        </Div>
                    </Grid>
                </Grid>
            </Div>
            <Div sx={{width: '100%',height: '85vh',}}>
                <Div sx={{width: '100%',height: '100%',background: 'black'}}>
                    <Grid container sx={{height: '100%',padding: '0px 70px 10px 70px',alignItems: 'center'}}>
                        <Grid item lg={12} xl={12} sx={{zIndex: '2',display: 'flex',justifyContent: 'center'}}>
                            <Typography variant="h1" sx={{color: 'white',fontSize: '18px'}}>Metrics Absorption</Typography>
                        </Grid>
                        <Grid item lg={2} xl={2} sx={{zIndex: '2'}}>
                            <CardAnalytics iconName={'Star'} value={'51%'} label={'PR INDEX'} percentage={'1% AVG.'} info={'Algoritmo que possibilita dar qualidade às matérias analisadas e determina a porcentagem das métricas absorvidas pelo leitor. Esse valor vai de -100% a 100%, o primeiro sendo muito negativo e o segundo, muito positivo. Quanto maior a porcentagem, melhor a performance qualitativa do conteúdo.'} />
                        </Grid>
                        <Grid item lg={2} xl={2} sx={{zIndex: '2'}}>
                            <CardAnalytics iconName={'Favorite'} value={'100%'} label={'SENTIMENT'} percentage={'0% AVG.'} results={true} info={'Tonalidade do resultado (muito positivo, positivo, negativo e neutro).'} />
                        </Grid>
                        <Grid item lg={2} xl={2} sx={{zIndex: '2'}}>
                            <CardAnalytics iconName={'EmojiEvents'} value={'44%'} label={'TIER 1'} percentage={'17% AVG.'} info={'Relevância e engajamento de um veículo, podendo ser classificado entre Tier 1 (muito relevante), Tier 2 (relevante) e Tier 3 (relevante, porém com pouco engajamento).'} />
                        </Grid>
                        <Grid item lg={2} xl={2} sx={{zIndex: '2'}}>
                            <CardAnalytics iconName={'Visibility'} value={'81%'} label={'VISUAL IMPACT'} percentage={'1% AVG.'} info={'Quantidade de resultados que apresentaram elementos visuais da marca.'} />
                        </Grid>
                        <Grid item lg={2} xl={2} sx={{zIndex: '2'}}>
                            <CardAnalytics iconName={'AlternateEmail'} value={'19%'} label={'CALL TO ACTION'} percentage={'57% AVG.'} info={'Porcentagem de resultados que apresentaram tomadas de ação, como link para o perfil da marca, hashtag da marca ou iniciativa e links externos.'} />
                        </Grid>
                        <Grid item lg={2} xl={2} sx={{zIndex: '2'}}>
                            <CardAnalytics iconName={'Key'} value={'5%'} label={'KEY MESSAGE'} percentage={'-81% AVG.'} info={'Porcentagem de resultados que apresentaram mensagens-chaves.'} />
                        </Grid>
                        <Grid item lg={2} xl={3} sx={{zIndex: '2'}}>
                            <CardAnalytics iconName={'VolumeUp'} value={'1%'} label={'ENDORSER'} percentage={'-80% AVG.'} info={'Quantidade de resultados que apresentaram porta-vozes da marca.'} />
                        </Grid>
                        {
                            [1,2,3,4].map((index) => (
                                <Grid key={index} item lg={2} xl={2} sx={{zIndex: '2'}}>
                                    <Div sx={{display: 'flex',flexDirection: 'column',alignItems: 'center',gap: 1.3,textAlign: 'center'}}>
                                        <Avatar sx={{width: '100px',height: '100px',color: 'white'}} />
                                        <Typography variant="h2" sx={{color: 'white',textShadow: '.2rem .2rem .2rem rgba(0, 0, 0, .5333333333333333)',fontSize: '34px'}}>1</Typography>
                                        <Typography variant="h2" sx={{textShadow: '.2rem .2rem .2rem rgba(0, 0, 0, .5333333333333333)',fontSize: '17px',color: '#00c2a8'}}>CAMILA MATEUS</Typography>
                                        <Typography variant="h2" sx={{color: 'white',textShadow: '.2rem .2rem .2rem rgba(0, 0, 0, .5333333333333333)',fontSize: '17px'}}>1 result (0,60%)</Typography>
                                    </Div>
                                </Grid>
                            ))
                        }
                    </Grid>
                </Div>
            </Div>
            <Div sx={{width: '100%',height: '55vh',background: 'rgb(46,46,46)'}}>
                <Grid container sx={{height: '100%',padding: '0px 70px 10px 70px',alignItems: 'center'}}>
                    <Grid item lg={12} xl={12} sx={{zIndex: '2',display: 'flex',marginBottom: '-60px'}}>
                        <Typography variant="h1" sx={{fontSize: '18px',color: '#00c2a8'}}>REPRESENTAÇÃO DAS MÉTRICAS</Typography>
                    </Grid>
                    <Grid item lg={12} xl={12} spacing={2} sx={{zIndex: '2'}}>
                        <Div sx={{display: 'flex',flexDirection: 'column',gap: 1.3}}>
                            <Typography variant="h4" sx={{color: 'white',textShadow: '.2rem .2rem .2rem rgba(0, 0, 0, .5333333333333333)',fontSize: '15px'}}><span style={{color: '#00c2a8'}}>Durante o período entre abril e o presente</span>, observamos uma diminuição de 20% na presença da marca, passando de 204 para 164 conteúdos avaliados. Esse declínio é resultado de uma queda nos conteúdos das mídias tradicionais, juntamente com uma queda considerável de mais de 50% no alcance potencial.</Typography>
                            <Typography variant="body" sx={{color: 'white',textShadow: '.2rem .2rem .2rem rgba(0, 0, 0, .5333333333333333)',fontSize: '15px'}}>No entanto, houve <span style={{color: '#00c2a8'}}>melhorias nas métricas qualitativas.</span> O <span style={{color: '#00c2a8'}}>PR Index registrou um aumento</span>, passando de 50,72% para 51,16%, impulsionado principalmente pelos temas proativos relacionados à Formula-E, nos quais entregaram a presença de ótimas métricas qualitativa.</Typography>
                            <Typography variant="body" sx={{color: 'white',textShadow: '.2rem .2rem .2rem rgba(0, 0, 0, .5333333333333333)',fontSize: '15px'}}>Os <span style={{color: '#00c2a8'}}>impactos visuais</span> também apresentaram um desempenho melhor, com um <span style={{color: '#00c2a8'}}>aumento de 1%.</span> O uso de <span style={{color: '#00c2a8'}}>Call to Action</span> foi a métrica que performou melhor, <span style={{color: '#00c2a8'}}>obtendo um aumento de 8%.</span> Presença de publicadores <span style={{color: '#00c2a8'}}>Tier 1 cresceu em 7%.</span></Typography>
                            <Typography variant="body" sx={{color: 'white',textShadow: '.2rem .2rem .2rem rgba(0, 0, 0, .5333333333333333)',fontSize: '15px'}}>Por outro lado, o uso de <span style={{color: '#00c2a8'}}>mensagens-chave</span>indicou um declínio de 24%, especialmente devido aos resultados nas mídias sociais, onde a coleta de dados foi principalmente orgânica, dificultando a absorção dessa métrica.</Typography>
                            <Typography variant="body" sx={{color: 'white',textShadow: '.2rem .2rem .2rem rgba(0, 0, 0, .5333333333333333)',fontSize: '15px'}}>A participação de <span style={{color: '#00c2a8'}}>endossantes</span> da marca foi de 1%. Esses endossantes foram mencionados em resultados relacionados à <span style={{color: '#00c2a8'}}>mudança na direção brasileira da JLR.</span></Typography>
                        </Div>
                    </Grid>
                </Grid>
            </Div>
            <Div sx={{width: '100%',height: '55vh',background: 'black'}}>
                <Grid container sx={{height: '100%',padding: '0px 70px 10px 70px',alignItems: 'center'}}>
                    <Grid item lg={4} xl={4} spacing={2} sx={{zIndex: '2'}}>
                        <Div sx={{display: 'flex',flexDirection: 'column',gap: 1.3}}>
                            <Typography variant="h4" sx={{color: 'white',fontSize: '18px'}}>Sentiment</Typography>
                            <Div sx={{display: 'flex',gap: 1.3}}>
                                <Gauge width={200} height={200} value={50} sx={() => ({
                                    [`& .${gaugeClasses.valueText}`]: {
                                        fontSize: 30,
                                        fill: 'white'
                                    },
                                    [`& .${gaugeClasses.referenceArc}`]: {
                                        fill: "white",
                                    },
                                    [`& .${gaugeClasses.valueArc}`]: {
                                        fill: "#00c2a8",
                                    },
                                })} />
                                <Div sx={{display: 'flex',flexDirection: 'column',gap: 1.3,justifyContent: 'center'}}>
                                    <Chip label="Very Positive" sx={{background: '#00c2a8',color: 'white',fontSize: '14px',fontWeight: 'bold',height: '20px'}} />
                                    <Chip label="Positive" sx={{background: '#00c2a8',color: 'white',fontSize: '14px',fontWeight: 'bold',height: '20px'}} />
                                </Div>
                            </Div>
                        </Div>
                    </Grid>
                    <Grid item lg={4} xl={4} spacing={2} sx={{zIndex: '2'}}>
                        <Div sx={{display: 'flex',flexDirection: 'column',gap: 1.3}}>
                            <Typography variant="h4" sx={{color: 'white',fontSize: '18px'}}>Tier</Typography>
                            <Div sx={{display: 'flex',gap: 1.3}}>
                                <Gauge width={200} height={200} value={20} sx={() => ({
                                    [`& .${gaugeClasses.valueText}`]: {
                                        fontSize: 30,
                                        fill: 'white'
                                    },
                                    [`& .${gaugeClasses.referenceArc}`]: {
                                        fill: "white",
                                    },
                                    [`& .${gaugeClasses.valueArc}`]: {
                                        fill: "#00c2a8",
                                    },
                                })} />
                                <Div sx={{display: 'flex',flexDirection: 'column',gap: 1.3,justifyContent: 'center'}}>
                                    <Chip label="Tier 1" sx={{background: '#00c2a8',color: 'white',fontSize: '14px',fontWeight: 'bold',height: '20px'}} />
                                    <Chip label="Tier 2" sx={{background: '#00c2a8',color: 'white',fontSize: '14px',fontWeight: 'bold',height: '20px'}} />
                                    <Chip label="Tier 3 " sx={{background: '#00c2a8',color: 'white',fontSize: '14px',fontWeight: 'bold',height: '20px'}} />
                                </Div>
                            </Div>
                        </Div>
                    </Grid>
                    <Grid item lg={4} xl={4} spacing={2} sx={{zIndex: '2'}}>
                        <Div sx={{display: 'flex',flexDirection: 'column',gap: 1.3}}>
                            <Typography variant="h4" sx={{color: 'white',fontSize: '18px'}}>Visual Impact</Typography>
                            <Div sx={{display: 'flex',gap: 1.3}}>
                                <Gauge width={200} height={200} value={60} sx={() => ({
                                    [`& .${gaugeClasses.valueText}`]: {
                                        fontSize: 30,
                                        fill: 'white'
                                    },
                                    [`& .${gaugeClasses.referenceArc}`]: {
                                        fill: "white",
                                    },
                                    [`& .${gaugeClasses.valueArc}`]: {
                                        fill: "#00c2a8",
                                    },
                                })} />
                                <Div sx={{display: 'flex',flexDirection: 'column',gap: 1.3,justifyContent: 'center'}}>
                                    <Chip label="Product" sx={{background: '#00c2a8',color: 'white',fontSize: '14px',fontWeight: 'bold',height: '20px'}} />
                                    <Chip label="Logo" sx={{background: '#00c2a8',color: 'white',fontSize: '14px',fontWeight: 'bold',height: '20px'}} />
                                    <Chip label="Event" sx={{background: '#00c2a8',color: 'white',fontSize: '14px',fontWeight: 'bold',height: '20px'}} />
                                    <Chip label="Endorser" sx={{background: '#00c2a8',color: 'white',fontSize: '14px',fontWeight: 'bold',height: '20px'}} />
                                    <Chip label="Campaign" sx={{background: '#00c2a8',color: 'white',fontSize: '14px',fontWeight: 'bold',height: '20px'}} />
                                </Div>
                            </Div>
                        </Div>
                    </Grid>
                </Grid>
            </Div>
            <Div sx={{width: '100%',height: '55vh',background: 'rgb(46,46,46)'}}>
                <Grid container sx={{height: '100%',padding: '0px 70px 10px 70px',alignItems: 'center'}}>
                    <Grid item lg={12} xl={12} sx={{zIndex: '2',display: 'flex',marginBottom: '-60px',justifyContent: 'center'}}>
                        <Typography variant="h1" sx={{fontSize: '18px',color: 'white'}}>Specialties & Activations</Typography>
                    </Grid>
                    <Grid item lg={width_1} xl={width_1} spacing={2} sx={{zIndex: '2',transition: 'all 0.2s ease-in-out',display: animation_2? 'none':'initial'}}>
                        <Div sx={{display: 'flex'}}>
                            <Typography variant="h4" sx={{color: 'white',fontSize: '18px'}}>Theme</Typography>
                            {
                                width_1===12? (
                                    <IconButton sx={{ml: 'auto'}} onClick={() => handleWidthWithAnimation(width_1,setWidth_1,setAnimation_1,setAnimation_2)}>
                                        <Remove />
                                    </IconButton>
                                ):(
                                    <IconButton sx={{ml: 'auto'}} onClick={() => handleWidthWithAnimation(width_1,setWidth_1,setAnimation_1,setAnimation_2)}>
                                        <Add />
                                    </IconButton>
                                )
                            }
                        </Div>
                        <TableContainer component={Paper}>
                            <Table sx={{minWidth: 650}} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Dessert (100g serving)</TableCell>
                                        <TableCell align="right">Calories</TableCell>
                                        <TableCell align="right">Fat&nbsp;(g)</TableCell>
                                        <TableCell align="right">Carbs&nbsp;(g)</TableCell>
                                        <TableCell align="right">Protein&nbsp;(g)</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rows.map((row) => (
                                        <TableRow
                                            key={row.name}
                                            sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                        >
                                            <TableCell component="th" scope="row">
                                                {row.name}
                                            </TableCell>
                                            <TableCell align="right">{row.calories}</TableCell>
                                            <TableCell align="right">{row.fat}</TableCell>
                                            <TableCell align="right">{row.carbs}</TableCell>
                                            <TableCell align="right">{row.protein}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                    <Grid item lg={4} xl={4} spacing={2} sx={{zIndex: '2',transition: 'all 0.2s ease-in-out',display: animation_1||animation_2? 'none':'initial'}}>
                        <Div sx={{display: 'flex',gap: 1,justifyContent: 'center'}}>
                            <CardAnalytics noAvatar={true} gauge={true} iconName={''} value={'58%'} label={'EARNED'} percentage={'-28% AVG.'} />
                            <CardAnalytics noAvatar={true} gauge={true} iconName={''} value={'16%'} label={'SHARED'} percentage={'5% AVG.'} />
                        </Div>
                    </Grid>
                    <Grid item lg={width_2} xl={width_2} spacing={2} sx={{zIndex: '2',transition: 'all 0.2s ease-in-out',display: animation_1? 'none':'initial'}}>
                        <Div sx={{display: 'flex'}}>
                            <Typography variant="h4" sx={{color: 'white',fontSize: '18px'}}>Initiatives</Typography>
                            {
                                width_2===12? (
                                    <IconButton sx={{ml: 'auto'}} onClick={() => handleWidthWithAnimation(width_2,setWidth_2,setAnimation_2,setAnimation_1)}>
                                        <Remove />
                                    </IconButton>
                                ):(
                                    <IconButton sx={{ml: 'auto'}} onClick={() => handleWidthWithAnimation(width_2,setWidth_2,setAnimation_2,setAnimation_1)}>
                                        <Add />
                                    </IconButton>
                                )
                            }
                        </Div>
                        <TableContainer component={Paper}>
                            <Table sx={{minWidth: 650}} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Dessert (100g serving)</TableCell>
                                        <TableCell align="right">Calories</TableCell>
                                        <TableCell align="right">Fat&nbsp;(g)</TableCell>
                                        <TableCell align="right">Carbs&nbsp;(g)</TableCell>
                                        <TableCell align="right">Protein&nbsp;(g)</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rows.map((row) => (
                                        <TableRow
                                            key={row.name}
                                            sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                        >
                                            <TableCell component="th" scope="row">
                                                {row.name}
                                            </TableCell>
                                            <TableCell align="right">{row.calories}</TableCell>
                                            <TableCell align="right">{row.fat}</TableCell>
                                            <TableCell align="right">{row.carbs}</TableCell>
                                            <TableCell align="right">{row.protein}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            </Div>
            <Div sx={{width: '100%',height: '55vh',background: 'black'}}>
                <Grid container sx={{height: '100%',padding: '0px 70px 10px 70px',alignItems: 'center'}}>
                    <Grid item lg={12} xl={12} sx={{zIndex: '2',display: 'flex',marginBottom: '-60px',justifyContent: 'center'}}>
                        <Typography variant="h1" sx={{fontSize: '18px',color: 'white'}}>Clipping</Typography>
                    </Grid>
                    <Grid item lg={4} xl={4} spacing={2} sx={{zIndex: '2'}}>
                        <Typography variant="h4" sx={{color: 'white',fontSize: '18px'}}>Origem: </Typography>
                        <PieChart
                            legend={
                                {
                                    slotProps: {
                                        legend: {
                                            labelStyle: {
                                                color: 'white',
                                                fill: 'white',
                                            }
                                        }
                                    }
                                }
                            }
                            series={[
                                {
                                    data: [
                                        {id: 0,value: 10,label: 'Espontânea'},
                                        {id: 1,value: 15,label: 'PinePR'},
                                    ],
                                },
                            ]}
                            width={500}
                            height={200}
                        />
                    </Grid>
                    <Grid item lg={4} xl={4} spacing={2} sx={{zIndex: '2'}}>
                        <Typography variant="h4" sx={{color: 'white',fontSize: '18px'}}>Assuntos: </Typography>
                        <PieChart
                            series={[
                                {
                                    data: [
                                        {id: 0,value: 10,label: 'Novo vice-presidente'},
                                        {id: 1,value: 15,label: 'Alicerce'},
                                        {id: 2,value: 12,label: 'Aporte Healthtech I'},
                                        {id: 3,value: 17,label: 'Citação'},
                                        {id: 4,value: 20,label: 'Contratação Philip'},
                                        {id: 5,value: 33,label: 'Aporte Healthtech II'},
                                    ],
                                },
                            ]}
                            width={500}
                            height={200}
                        />
                    </Grid>
                    <Grid item lg={4} xl={4} spacing={2} sx={{zIndex: '2'}}>
                        <Typography variant="h4" sx={{color: 'white',fontSize: '18px'}}>Temas: </Typography>
                        <PieChart
                            series={[
                                {
                                    data: [
                                        {id: 0,value: 10,label: 'Investidas Wayra'},
                                        {id: 1,value: 15,label: 'Parcerias Wayra'},
                                        {id: 3,value: 15,label: 'Promoção de eventos'},
                                    ],
                                },
                            ]}
                            width={500}
                            height={200}
                        />
                    </Grid>
                </Grid>
            </Div>
            <Div sx={{width: '100%',height: '55vh',background: 'rgb(46,46,46)',padding: '20px'}}>
                <Grid item lg={12} xl={12} sx={{zIndex: '2',display: 'flex',marginTop: '30px',marginBottom: '-60px',justifyContent: 'center'}}>
                    <Typography variant="h1" sx={{fontSize: '18px',color: 'white'}}>OKRS</Typography>
                </Grid>
                <Grid container spacing={2} sx={{height: '100%',padding: '0px 50px 10px 50px',alignItems: 'center'}}>
                    <Grid item lg={4} xl={4} spacing={2} sx={{zIndex: '2'}}>
                        <Card sx={{border: '1px solid black'}}>
                            <CardHeader
                                sx={{color: 'black'}}
                                title="KR1"
                                subheader="Aumentar a visibilidade da marca"
                            />
                            <Div>
                                <Div sx={{display: 'flex',justifyContent: 'space-evenly',width: '100%'}}>
                                    <Div sx={{display: 'flex',flexDirection: 'column',gap: 2,textAlign: 'center'}}>
                                        <Typography variant="body" sx={{fontSize: '15px',color: 'black'}}>Meta KR1</Typography>
                                        <Typography variant="body" sx={{fontSize: '20px',color: 'black'}}>100</Typography>
                                        <Div sx={{display: 'flex',gap: 2,alignItems: 'center'}}>
                                            <Box sx={{width: '50px',height: '25px',background: 'black'}}></Box>
                                            <Typography variant="body" sx={{fontSize: '15px',color: 'black'}}>KR1 Atingido</Typography>
                                        </Div>
                                    </Div>
                                    <Div sx={{display: 'flex',flexDirection: 'column',gap: 2}}>
                                        <Typography variant="body" sx={{fontSize: '15px',color: 'black'}}>/</Typography>
                                    </Div>
                                    <Div sx={{display: 'flex',flexDirection: 'column',gap: 2,textAlign: 'center'}}>
                                        <Typography variant="body" sx={{fontSize: '15px',color: 'black'}}>KR1 Atingido</Typography>
                                        <Typography variant="body" sx={{fontSize: '20px',color: 'black'}}>104</Typography>
                                        <Div sx={{display: 'flex',gap: 2,alignItems: 'center'}}>
                                            <Box sx={{width: '50px',height: '25px',background: 'black'}}></Box>
                                            <Typography variant="body" sx={{fontSize: '15px',color: 'black'}}>Meta KR1</Typography>
                                        </Div>
                                    </Div>
                                </Div>
                                <Div sx={{width: '100%',padding: '20px'}}>
                                    <Div sx={{width: '100%',height: '50px',background: 'black'}}> </Div>
                                </Div>
                            </Div>
                        </Card>
                    </Grid>
                    <Grid item lg={4} xl={4} spacing={2} sx={{zIndex: '2'}}>
                        <Card sx={{border: '1px solid black'}}>
                            <CardHeader
                                sx={{color: 'black'}}
                                title="KR1"
                                subheader="Aumentar a visibilidade da marca"
                            />
                            <Div>
                                <Div sx={{display: 'flex',justifyContent: 'space-evenly',width: '100%'}}>
                                    <Div sx={{display: 'flex',flexDirection: 'column',gap: 2,textAlign: 'center'}}>
                                        <Typography variant="body" sx={{fontSize: '15px',color: 'black'}}>Meta KR1</Typography>
                                        <Typography variant="body" sx={{fontSize: '20px',color: 'black'}}>100</Typography>
                                        <Div sx={{display: 'flex',gap: 2,alignItems: 'center'}}>
                                            <Box sx={{width: '50px',height: '25px',background: 'black'}}></Box>
                                            <Typography variant="body" sx={{fontSize: '15px',color: 'black'}}>KR1 Atingido</Typography>
                                        </Div>
                                    </Div>
                                    <Div sx={{display: 'flex',flexDirection: 'column',gap: 2}}>
                                        <Typography variant="body" sx={{fontSize: '15px',color: 'black'}}>/</Typography>
                                    </Div>
                                    <Div sx={{display: 'flex',flexDirection: 'column',gap: 2,textAlign: 'center'}}>
                                        <Typography variant="body" sx={{fontSize: '15px',color: 'black'}}>KR1 Atingido</Typography>
                                        <Typography variant="body" sx={{fontSize: '20px',color: 'black'}}>104</Typography>
                                        <Div sx={{display: 'flex',gap: 2,alignItems: 'center'}}>
                                            <Box sx={{width: '50px',height: '25px',background: 'black'}}></Box>
                                            <Typography variant="body" sx={{fontSize: '15px',color: 'black'}}>Meta KR1</Typography>
                                        </Div>
                                    </Div>
                                </Div>
                                <Div sx={{width: '100%',padding: '20px'}}>
                                    <Div sx={{width: '100%',height: '50px',background: 'black'}}> </Div>
                                </Div>
                            </Div>
                        </Card>
                    </Grid>
                    <Grid item lg={4} xl={4} spacing={2} sx={{zIndex: '2'}}>
                        <Card sx={{border: '1px solid black'}}>
                            <CardHeader
                                sx={{color: 'black'}}
                                title="KR1"
                                subheader="Aumentar a visibilidade da marca"
                            />
                            <Div>
                                <Div sx={{display: 'flex',justifyContent: 'space-evenly',width: '100%'}}>
                                    <Div sx={{display: 'flex',flexDirection: 'column',gap: 2,textAlign: 'center'}}>
                                        <Typography variant="body" sx={{fontSize: '15px',color: 'black'}}>Meta KR1</Typography>
                                        <Typography variant="body" sx={{fontSize: '20px',color: 'black'}}>100</Typography>
                                        <Div sx={{display: 'flex',gap: 2,alignItems: 'center'}}>
                                            <Box sx={{width: '50px',height: '25px',background: 'black'}}></Box>
                                            <Typography variant="body" sx={{fontSize: '15px',color: 'black'}}>KR1 Atingido</Typography>
                                        </Div>
                                    </Div>
                                    <Div sx={{display: 'flex',flexDirection: 'column',gap: 2}}>
                                        <Typography variant="body" sx={{fontSize: '15px',color: 'black'}}>/</Typography>
                                    </Div>
                                    <Div sx={{display: 'flex',flexDirection: 'column',gap: 2,textAlign: 'center'}}>
                                        <Typography variant="body" sx={{fontSize: '15px',color: 'black'}}>KR1 Atingido</Typography>
                                        <Typography variant="body" sx={{fontSize: '20px',color: 'black'}}>104</Typography>
                                        <Div sx={{display: 'flex',gap: 2,alignItems: 'center'}}>
                                            <Box sx={{width: '50px',height: '25px',background: 'black'}}></Box>
                                            <Typography variant="body" sx={{fontSize: '15px',color: 'black'}}>Meta KR1</Typography>
                                        </Div>
                                    </Div>
                                </Div>
                                <Div sx={{width: '100%',padding: '20px'}}>
                                    <Div sx={{width: '100%',height: '50px',background: 'black'}}> </Div>
                                </Div>
                            </Div>
                        </Card>
                    </Grid>
                </Grid>
            </Div>
            <Div sx={{width: '100%',height: '55vh',background: 'black'}}>
                <Grid container sx={{height: '100%',padding: '0px 70px 10px 70px',alignItems: 'center'}}>
                    <Grid item lg={4} xl={4} sx={{zIndex: '2',gap: 1.3,display: 'flex',flexDirection: 'column'}}>
                        <Typography variant="h4" sx={{fontSize: '18px',color: '#00c2a8'}}>Enzo Fittipaldi Junta-se para os pilotos da Jaguar TCS Racing</Typography>
                        <Typography variant="body" sx={{fontSize: '15px',color: 'white'}}>A JLR anunciou o Enzo como seu novo piloto, isso resultou em 31 publicações em mídias tradicionais. Essa cobertura alcançou um total de <span style={{color: '#00c2a8'}}>2.4 milhões de  usuários</span></Typography>
                        <Typography variant="body" sx={{fontSize: '15px',color: 'white'}}>Qualitativamente, os resultados atingiram uma<span style={{color: '#00c2a8'}}> média de 45% no PR Index, alcançando 71% em impactos visuais.</span> No entanto, não houve presença de call to actions e de mensagem chave.</Typography>
                    </Grid>
                    <Grid item lg={4} xl={4} spacing={2} sx={{zIndex: '2',gap: 1.3,display: 'flex',flexDirection: 'column'}}>
                        <Typography variant="h4" sx={{fontSize: '18px',color: '#00c2a8'}}>JAGUAR TCS RACING AMPLIA SUA LIDERANÇA APÓS ETAPA EM XANGAI</Typography>
                        <Typography variant="body" sx={{fontSize: '15px',color: 'white'}}>A divulgação da corrida em Xangai resultou em 21 publicações em mídias tradicionais, <span style={{color: '#00c2a8'}}>alcançando um total de 2 milhões de usuários.</span> Destaca-se a relevância do veículo <span style={{color: '#00c2a8'}}>Portal IG</span> e <span style={{color: '#00c2a8'}}>Terra</span>, que juntos contribuíram com mais de <span style={{color: '#00c2a8'}}>1.6 milhões de usuários únicos.</span></Typography>
                        <Typography variant="body" sx={{fontSize: '15px',color: 'white'}}>Em termos qualitativos, alcançamos um <span style={{color: '#00c2a8'}}>percentual de 43% no PR Index.</span> Houve uma <span style={{color: '#00c2a8'}}>absorção de impactos visuais de 62%.</span> Além disso, obteve uma <span style={{color: '#00c2a8'}}>boa assimilação das mensagens-chave (comparando com as demais iniciativas)</span>, especialmente aquela relacionada à <span style={{color: '#00c2a8'}}>quantidade de pontos que a Jaguar tem no campeonato.</span></Typography>
                    </Grid>
                    <Grid item lg={4} xl={4} spacing={2} sx={{zIndex: '2',gap: 1.3,display: 'flex',flexDirection: 'column'}}>
                        <Typography variant="h4" sx={{fontSize: '18px',color: '#00c2a8'}}>JLR promove experiência exclusiva</Typography>
                        <Typography variant="body" sx={{fontSize: '15px',color: 'white'}}>A JLR promoveu uma experiencia exclusiva de luxo, que ocasionou em 14 publicações em mídias digitais. Essa cobertura alcançou um total de 117 mil de usuários, com destaque para o Projeto Onçafari, que contribuiu com mais de 55 mil usuários.</Typography>
                        <Typography variant="body" sx={{fontSize: '15px',color: 'white'}}>Qualitativamente, os resultados foram excelentes. Obtendo uma média de 71% no PR Index, alcançando 100% em impactos visuais e 57% em call to action. No entanto, não houve presença de mensagem chave.</Typography>
                    </Grid>
                </Grid>
            </Div>
            <Div sx={{width: '100%',height: '55vh',background: 'rgb(46,46,46)'}}>
                <Grid container sx={{height: '100%',padding: '0px 70px 10px 70px',alignItems: 'center'}}>
                    <Grid item lg={12} xl={12} sx={{zIndex: '2',display: 'flex',justifyContent: 'center'}}>
                        <Typography variant="h1" sx={{color: 'white',fontSize: '18px'}}>TOP POSTS</Typography>
                    </Grid>
                    <Grid item lg={4} xl={4} spacing={2} sx={{zIndex: '2'}}>
                        <Div sx={{display: 'flex',flexDirection: 'column',gap: 1.3}}>
                            <Typography variant="h4" sx={{color: 'white',fontSize: '18px'}}>Portal IG</Typography>
                            <Div sx={{display: 'flex',gap: 1.3}}>
                                <img width={'95%'} height={'250px'} style={{borderRadius: '10px'}} src="https://precision.nyc3.cdn.digitaloceanspaces.com/public/img/dashboard/155/widget/3d0bd1b7-df9e-4182-82cd-cd0431cf30e2.png" alt="Sentiment" />
                            </Div>
                        </Div>
                    </Grid>
                    <Grid item lg={4} xl={4} spacing={2} sx={{zIndex: '2'}}>
                        <Div sx={{display: 'flex',flexDirection: 'column',gap: 1.3}}>
                            <Typography variant="h4" sx={{color: 'white',fontSize: '18px'}}>Portal IG</Typography>
                            <Div sx={{display: 'flex',gap: 1.3}}>
                                <img width={'95%'} height={'250px'} style={{borderRadius: '10px'}} src="https://precision.nyc3.cdn.digitaloceanspaces.com/public/img/dashboard/155/widget/3d0bd1b7-df9e-4182-82cd-cd0431cf30e2.png" alt="Sentiment" />
                            </Div>
                        </Div>
                    </Grid>
                    <Grid item lg={4} xl={4} spacing={2} sx={{zIndex: '2'}}>
                        <Div sx={{display: 'flex',flexDirection: 'column',gap: 1.3}}>
                            <Typography variant="h4" sx={{color: 'white',fontSize: '18px'}}>Portal IG</Typography>
                            <Div sx={{display: 'flex',gap: 1.3}}>
                                <img width={'95%'} height={'250px'} style={{borderRadius: '10px'}} src="https://precision.nyc3.cdn.digitaloceanspaces.com/public/img/dashboard/155/widget/3d0bd1b7-df9e-4182-82cd-cd0431cf30e2.png" alt="Sentiment" />
                            </Div>
                        </Div>
                    </Grid>
                </Grid>
            </Div>
            <Div sx={{width: '100%',height: '55vh',background: 'black'}}>
                <Grid container sx={{height: '100%',padding: '20px 70px 20px 70px',alignItems: 'center'}}>
                    <Grid item lg={12} xl={12} sx={{zIndex: '2',display: 'flex',justifyContent: 'center'}}>
                        <Typography variant="h1" sx={{color: 'white',fontSize: '18px',mb: '20px'}}>House Of Brands</Typography>
                    </Grid>
                    <Grid item lg={2} xl={2.4} sx={{zIndex: '2'}}>
                        <CardAnalytics noAvatar={true} iconName={'DirectionsCar'} value={'58%'} label={'JAGUAR'} percentage={'95 results I 8.5M alcance'} />
                    </Grid>
                    <Grid item lg={2} xl={2.4} sx={{zIndex: '2'}}>
                        <CardAnalytics noAvatar={true} iconName={'DirectionsCar'} value={'16%'} label={'DEFENDER'} percentage={'27 results I 678K alcance'} />
                    </Grid>
                    <Grid item lg={2} xl={2.4} sx={{zIndex: '2'}}>
                        <CardAnalytics noAvatar={true} iconName={'DirectionsCar'} value={'16%'} label={'JLR'} percentage={'26 results I 304K alcance'} />
                    </Grid>
                    <Grid item lg={2} xl={2.4} sx={{zIndex: '2'}}>
                        <CardAnalytics noAvatar={true} iconName={'DirectionsCar'} value={'7%'} label={'RANGE ROVER'} percentage={'11 results I 2034K alcance'} />
                    </Grid>
                    <Grid item lg={2} xl={2.4} sx={{zIndex: '2'}}>
                        <CardAnalytics noAvatar={true} iconName={'DirectionsCar'} value={'19%'} label={'DISCOVERY'} percentage={'5 results I 334K alcance'} />
                    </Grid>
                    <Grid item lg={12} xl={12} sx={{zIndex: '2',display: 'flex',mt: 2,mb: 1}}>
                        <Typography variant="h1" sx={{fontSize: '16px',color: '#00c2a8'}}>REPRESENTAÇÃO DOS MERCADOS</Typography>
                    </Grid>
                    <Grid item lg={12} xl={12} sx={{zIndex: '2',display: 'flex'}}>
                        <Typography variant="h1" sx={{color: 'white',fontSize: '15px'}}><span style={{color: '#00c2a8'}}>JLR:</span> o pilar foi marcado pelos resultados relacionados aos empréstimos de veículos e a mudança na direção brasileira da JLR.</Typography>
                    </Grid>
                    <Grid item lg={12} xl={12} sx={{zIndex: '2',display: 'flex'}}>
                        <Typography variant="h1" sx={{color: 'white',fontSize: '15px'}}><span style={{color: '#00c2a8'}}>Discovery:</span> pilar destacado pelo projeto de influenciadores.</Typography>
                    </Grid>
                    <Grid item lg={12} xl={12} sx={{zIndex: '2',display: 'flex'}}>
                        <Typography variant="h1" sx={{color: 'white',fontSize: '15px'}}><span style={{color: '#00c2a8'}}>Defender:</span> foi o segundo pilar mais presente, teve relevância em publicações ongoing de citações gerais do mercado. </Typography>
                    </Grid>
                    <Grid item lg={12} xl={12} sx={{zIndex: '2',display: 'flex'}}>
                        <Typography variant="h1" sx={{color: 'white',fontSize: '15px'}}><span style={{color: '#00c2a8'}}>Range Rover:</span> destaca-se pelo projeto de influenciadores e publicações gerais do mercado.</Typography>
                    </Grid>
                    <Grid item lg={12} xl={12} sx={{zIndex: '2',display: 'flex'}}>
                        <Typography variant="h1" sx={{color: 'white',fontSize: '15px'}}><span style={{color: '#00c2a8'}}>Jaguar:</span> foi o pilar com maior frequência de resultados, especialmente pelos conteúdos relacionados à Fórmula E.</Typography>
                    </Grid>
                </Grid>
            </Div>
            <Div sx={{width: '100%',height: '45vh',background: 'rgb(46,46,46)'}}>
                <Grid container sx={{height: '100%',padding: '20px 70px 20px 70px',alignItems: 'center'}}>
                    <Grid item lg={2} xl={2} sx={{zIndex: '2'}}>
                        <CardAnalytics iconName={'Language'} value={'121'} label={'SITE (BLOG)'} percentage={'-25% AVG.'} />
                    </Grid>
                    <Grid item lg={2} xl={2} sx={{zIndex: '2'}}>
                        <CardAnalytics iconName={'Instagram'} value={'38'} label={'INSTAGRAM'} percentage={'9% AVG.'} />
                    </Grid>
                    <Grid item lg={2} xl={2} sx={{zIndex: '2'}}>
                        <CardAnalytics iconName={'Newspaper'} value={'2'} label={'NEWSPAPER'} percentage={'100% AVG.'} />
                    </Grid>
                    <Grid item lg={2} xl={2} sx={{zIndex: '2'}}>
                        <CardAnalytics iconName={'Facebook'} value={'2'} label={'FACEBOOK'} percentage={'-50% AVG.'} />
                    </Grid>
                    <Grid item lg={2} xl={4} sx={{zIndex: '2'}}>
                        <Div sx={{display: 'flex',flexDirection: 'column',gap: 1.3}}>
                            <Typography variant="h4" sx={{color: 'white',fontSize: '18px'}}>Formats</Typography>
                            <Div sx={{display: 'flex',gap: 1.3}}>
                                <Gauge width={200} height={200} value={60} sx={() => ({
                                    [`& .${gaugeClasses.valueText}`]: {
                                        fontSize: 30,
                                    },
                                    [`& .${gaugeClasses.referenceArc}`]: {
                                        fill: "white",
                                    },
                                    [`& .${gaugeClasses.valueArc}`]: {
                                        fill: "#00c2a8",
                                    },
                                })} />
                                <Div sx={{display: 'flex',flexDirection: 'column',gap: 1.3,justifyContent: 'center'}}>
                                    <Chip label="Online" sx={{background: '#00c2a8',color: 'white',fontSize: '14px',fontWeight: 'bold',height: '20px'}} />
                                    <Chip label="Feed" sx={{background: '#00c2a8',color: 'white',fontSize: '14px',fontWeight: 'bold',height: '20px'}} />
                                    <Chip label="Reels" sx={{background: '#00c2a8',color: 'white',fontSize: '14px',fontWeight: 'bold',height: '20px'}} />
                                    <Chip label="Stories" sx={{background: '#00c2a8',color: 'white',fontSize: '14px',fontWeight: 'bold',height: '20px'}} />
                                    <Chip label="Print" sx={{background: '#00c2a8',color: 'white',fontSize: '14px',fontWeight: 'bold',height: '20px'}} />
                                </Div>
                            </Div>
                        </Div>
                    </Grid>
                </Grid>
            </Div>
            <Div sx={{width: '100%',height: '40vh',background: 'black'}}>
                <Grid container sx={{height: '100%',padding: '70px',alignItems: 'center'}}>
                    <Grid item lg={12} xl={12} sx={{zIndex: '2',display: 'flex',mb: 2}}>
                        <Typography variant="h1" sx={{color: 'white',fontSize: '18px'}}>Publishers</Typography>
                    </Grid>
                    <Grid item lg={2} xl={2} sx={{zIndex: '2'}}>
                        <Div sx={{display: 'flex',flexDirection: 'column',alignItems: 'center'}}>
                            <img width={'95%'} height={'150px'} style={{borderRadius: '10px'}} src="https://precision.nyc3.cdn.digitaloceanspaces.com/public/img/publisher/11591/profile_img/a40dc8ac-ed72-4587-8236-ff14c08d2aff.png" alt="Sentiment" />
                            <Typography variant="h2" sx={{color: 'white',fontSize: '15px'}}>Globo</Typography>
                            <Div sx={{display: 'flex',gap: 1.3}}>
                                <Chip label="SP" sx={{background: 'hsla(0,0%,100%,0.13333333333333333)',color: 'white',fontSize: '10px',fontWeight: 'bold',height: '20px'}} />
                                <Chip label="Corporate" sx={{background: 'hsla(0,0%,100%,0.13333333333333333)',color: 'white',fontSize: '10px',fontWeight: 'bold',height: '20px'}} />
                            </Div>
                        </Div>
                    </Grid>
                    <Grid item lg={2} xl={2} sx={{zIndex: '2'}}>
                        <Div sx={{display: 'flex',flexDirection: 'column',alignItems: 'center'}}>
                            <img width={'95%'} height={'150px'} style={{borderRadius: '10px'}} src="https://precision.nyc3.cdn.digitaloceanspaces.com/public/img/publisher/11591/profile_img/a40dc8ac-ed72-4587-8236-ff14c08d2aff.png" alt="Sentiment" />
                            <Typography variant="h2" sx={{color: 'white',fontSize: '15px'}}>Globo</Typography>
                            <Div sx={{display: 'flex',gap: 1.3}}>
                                <Chip label="SP" sx={{background: 'hsla(0,0%,100%,0.13333333333333333)',color: 'white',fontSize: '10px',fontWeight: 'bold',height: '20px'}} />
                                <Chip label="Corporate" sx={{background: 'hsla(0,0%,100%,0.13333333333333333)',color: 'white',fontSize: '10px',fontWeight: 'bold',height: '20px'}} />
                            </Div>
                        </Div>
                    </Grid>
                    <Grid item lg={2} xl={2} sx={{zIndex: '2'}}>
                        <Div sx={{display: 'flex',flexDirection: 'column',alignItems: 'center'}}>
                            <img width={'95%'} height={'150px'} style={{borderRadius: '10px'}} src="https://precision.nyc3.cdn.digitaloceanspaces.com/public/img/publisher/11591/profile_img/a40dc8ac-ed72-4587-8236-ff14c08d2aff.png" alt="Sentiment" />
                            <Typography variant="h2" sx={{color: 'white',fontSize: '15px'}}>Globo</Typography>
                            <Div sx={{display: 'flex',gap: 1.3}}>
                                <Chip label="SP" sx={{background: 'hsla(0,0%,100%,0.13333333333333333)',color: 'white',fontSize: '10px',fontWeight: 'bold',height: '20px'}} />
                                <Chip label="Corporate" sx={{background: 'hsla(0,0%,100%,0.13333333333333333)',color: 'white',fontSize: '10px',fontWeight: 'bold',height: '20px'}} />
                            </Div>
                        </Div>
                    </Grid>
                    <Grid item lg={2} xl={2} sx={{zIndex: '2'}}>
                        <Div sx={{display: 'flex',flexDirection: 'column',alignItems: 'center'}}>
                            <img width={'95%'} height={'150px'} style={{borderRadius: '10px'}} src="https://precision.nyc3.cdn.digitaloceanspaces.com/public/img/publisher/11591/profile_img/a40dc8ac-ed72-4587-8236-ff14c08d2aff.png" alt="Sentiment" />
                            <Typography variant="h2" sx={{color: 'white',fontSize: '15px'}}>Globo</Typography>
                            <Div sx={{display: 'flex',gap: 1.3}}>
                                <Chip label="SP" sx={{background: 'hsla(0,0%,100%,0.13333333333333333)',color: 'white',fontSize: '10px',fontWeight: 'bold',height: '20px'}} />
                                <Chip label="Corporate" sx={{background: 'hsla(0,0%,100%,0.13333333333333333)',color: 'white',fontSize: '10px',fontWeight: 'bold',height: '20px'}} />
                            </Div>
                        </Div>
                    </Grid>
                    <Grid item lg={2} xl={2} sx={{zIndex: '2'}}>
                        <Div sx={{display: 'flex',flexDirection: 'column',alignItems: 'center'}}>
                            <img width={'95%'} height={'150px'} style={{borderRadius: '10px'}} src="https://precision.nyc3.cdn.digitaloceanspaces.com/public/img/publisher/11591/profile_img/a40dc8ac-ed72-4587-8236-ff14c08d2aff.png" alt="Sentiment" />
                            <Typography variant="h2" sx={{color: 'white',fontSize: '15px'}}>Globo</Typography>
                            <Div sx={{display: 'flex',gap: 1.3}}>
                                <Chip label="SP" sx={{background: 'hsla(0,0%,100%,0.13333333333333333)',color: 'white',fontSize: '10px',fontWeight: 'bold',height: '20px'}} />
                                <Chip label="Corporate" sx={{background: 'hsla(0,0%,100%,0.13333333333333333)',color: 'white',fontSize: '10px',fontWeight: 'bold',height: '20px'}} />
                            </Div>
                        </Div>
                    </Grid>
                    <Grid item lg={2} xl={2} sx={{zIndex: '2'}}>
                        <Div sx={{display: 'flex',flexDirection: 'column',alignItems: 'center'}}>
                            <img width={'95%'} height={'150px'} style={{borderRadius: '10px'}} src="https://precision.nyc3.cdn.digitaloceanspaces.com/public/img/publisher/11591/profile_img/a40dc8ac-ed72-4587-8236-ff14c08d2aff.png" alt="Sentiment" />
                            <Typography variant="h2" sx={{color: 'white',fontSize: '15px'}}>Globo</Typography>
                            <Div sx={{display: 'flex',gap: 1.3}}>
                                <Chip label="SP" sx={{background: 'hsla(0,0%,100%,0.13333333333333333)',color: 'white',fontSize: '10px',fontWeight: 'bold',height: '20px'}} />
                                <Chip label="Corporate" sx={{background: 'hsla(0,0%,100%,0.13333333333333333)',color: 'white',fontSize: '10px',fontWeight: 'bold',height: '20px'}} />
                            </Div>
                        </Div>
                    </Grid>
                </Grid>
            </Div>
            <Div sx={{width: '100%',height: '40vh',background: 'rgb(46,46,46)'}}>
                <Grid container sx={{height: '100%',padding: '70px',alignItems: 'center'}}>
                    <Grid item lg={12} xl={12} sx={{zIndex: '2',display: 'flex',justifyContent: 'center'}}>
                        <Typography variant="h1" sx={{color: 'white',fontSize: '18px'}}>Social Media</Typography>
                    </Grid>
                    <Grid item lg={2} xl={2.2} sx={{zIndex: '2'}}>
                        <CardAnalytics iconName={'Groups'} value={'7K'} label={'TOTAL ENGAGEMENT'} percentage={'-6% AVG.'} />
                    </Grid>
                    <Grid item lg={2} xl={2.2} sx={{zIndex: '2'}}>
                        <CardAnalytics iconName={'ThumbUp'} value={'7K'} label={'LIKES / REACTIONS'} percentage={'-6% AVG.'} />
                    </Grid>
                    <Grid item lg={2} xl={1.6} sx={{zIndex: '2'}}>
                        <CardAnalytics iconName={'Share'} value={'158'} label={'SHARES'} percentage={'60% AVG.'} />
                    </Grid>
                    <Grid item lg={2} xl={2.2} sx={{zIndex: '2'}}>
                        <CardAnalytics iconName={'Article'} value={'299'} label={'TOTAL COMMENTS'} percentage={'-28% AVG.'} />
                    </Grid>
                    <Grid item lg={3} xl={3.2} spacing={2} sx={{zIndex: '2',maxHeight: '220px',overflowY: 'scroll'}}>
                        <Div sx={{display: 'flex',flexDirection: 'column',gap: 1.3}}>
                            <Typography variant="h4" sx={{textShadow: '.2rem .2rem .2rem rgba(0, 0, 0, .5333333333333333)',fontSize: '18px',color: '#00c2a8'}}>COBERTURA NAS REDES SOCIAIS</Typography>
                            <Typography variant="body" sx={{color: 'white',textShadow: '.2rem .2rem .2rem rgba(0, 0, 0, .5333333333333333)',fontSize: '15px'}}>As publicações nas redes sociais somaram <span style={{color: '#00c2a8'}}>7.285 em engajamento</span>, com destaque para <span style={{color: '#00c2a8'}}>mais de 7 mil curtidas</span>, impulsionadas em grande parte por uma publicação do <span style={{color: '#00c2a8'}}>influenciador Antônio Camarotti.</span> Ele apareceu no evento da Agrishow postando uma foto ao lado de um defender indicando o seu publico a conhecer esse stand, com isso gerou <span style={{color: '#00c2a8'}}>mais de 3 mil curtidas.</span> </Typography>
                            <Typography variant="body" sx={{color: 'white',textShadow: '.2rem .2rem .2rem rgba(0, 0, 0, .5333333333333333)',fontSize: '15px'}}>As publicações feitas pelo veículo <span style={{color: '#00c2a8'}}>Dakar Overland</span> resultaram em <span style={{color: '#00c2a8'}}>125 compartilhamentos</span>, sendo a responsável pela maior parte do total de 158 compartilhamentos.</Typography>
                            <Typography variant="body" sx={{color: 'white',textShadow: '.2rem .2rem .2rem rgba(0, 0, 0, .5333333333333333)',fontSize: '15px'}}>É importante notar que, devido ao baixo número de publicações neste mês, o engajamento geral foi menor. No entanto, ainda coletamos <span style={{color: '#00c2a8'}}>6 comentários positivos</span> em relação às marcas e ativações, como por exemplo </Typography>
                        </Div>
                    </Grid>
                </Grid>
            </Div>
            <Div sx={{width: '100%',height: '40vh',background: 'black'}}>
                <Grid container sx={{height: '100%',padding: '0px 70px 10px 70px',alignItems: 'center'}}>
                    <Grid item lg={12} xl={12} sx={{zIndex: '2',display: 'flex',justifyContent: 'center'}}>
                        <Typography variant="h1" sx={{color: 'white',fontSize: '18px'}}>TOP POSTS</Typography>
                    </Grid>
                    <Grid item lg={4} xl={4} spacing={2} sx={{zIndex: '2'}}>
                        <Div sx={{display: 'flex',flexDirection: 'column',gap: 1.3}}>
                            <Typography variant="h4" sx={{color: 'white',fontSize: '18px'}}>Portal IG</Typography>
                            <Div sx={{display: 'flex',gap: 1.3}}>
                                <img width={'95%'} height={'200px'} style={{borderRadius: '10px'}} src="https://precision.nyc3.cdn.digitaloceanspaces.com/public/img/dashboard/155/widget/3d0bd1b7-df9e-4182-82cd-cd0431cf30e2.png" alt="Sentiment" />
                            </Div>
                        </Div>
                    </Grid>
                    <Grid item lg={4} xl={4} spacing={2} sx={{zIndex: '2'}}>
                        <Div sx={{display: 'flex',flexDirection: 'column',gap: 1.3}}>
                            <Typography variant="h4" sx={{color: 'white',fontSize: '18px'}}>Portal IG</Typography>
                            <Div sx={{display: 'flex',gap: 1.3}}>
                                <img width={'95%'} height={'200px'} style={{borderRadius: '10px'}} src="https://precision.nyc3.cdn.digitaloceanspaces.com/public/img/dashboard/155/widget/3d0bd1b7-df9e-4182-82cd-cd0431cf30e2.png" alt="Sentiment" />
                            </Div>
                        </Div>
                    </Grid>
                    <Grid item lg={4} xl={4} spacing={2} sx={{zIndex: '2'}}>
                        <Div sx={{display: 'flex',flexDirection: 'column',gap: 1.3}}>
                            <Typography variant="h4" sx={{color: 'white',fontSize: '18px'}}>Portal IG</Typography>
                            <Div sx={{display: 'flex',gap: 1.3}}>
                                <img width={'95%'} height={'200px'} style={{borderRadius: '10px'}} src="https://precision.nyc3.cdn.digitaloceanspaces.com/public/img/dashboard/155/widget/3d0bd1b7-df9e-4182-82cd-cd0431cf30e2.png" alt="Sentiment" />
                            </Div>
                        </Div>
                    </Grid>
                </Grid>
            </Div>
            <Div sx={{width: '100%',height: '40vh',background: 'rgb(46,46,46)'}}>
                <Grid container sx={{height: '100%',padding: '70px',alignItems: 'center'}}>
                    <Grid item lg={2} xl={2.2} sx={{zIndex: '2'}}>
                        <CardAnalytics iconName={'https://precision.nyc3.cdn.digitaloceanspaces.com/public/img/competitor/104/b36a6ac9-e996-4e6c-993e-f2f6be83d9e8.svg'} value={'43'} label={'NISSAN'} percentage={'43 results (25,90%)'} noAvatar />
                    </Grid>
                    <Grid item lg={2} xl={2.2} sx={{zIndex: '2'}}>
                        <CardAnalytics iconName={'https://precision.nyc3.cdn.digitaloceanspaces.com/public/img/competitor/96/c1f697fd-3615-448d-a074-fee5f8ec8ba4.png'} value={'33'} label={'PORSCHE'} percentage={'33 results (19,88%)'} noAvatar />
                    </Grid>
                    <Grid item lg={2} xl={1.6} sx={{zIndex: '2'}}>
                        <CardAnalytics iconName={'https://precision.nyc3.cdn.digitaloceanspaces.com/public/img/competitor/100/896e5b66-6736-46de-8fa5-121043121f2f.svg'} value={'10'} label={'TOYOTA'} percentage={'10 results (6,02%)'} noAvatar />
                    </Grid>
                    <Grid item lg={2} xl={2.2} sx={{zIndex: '2'}}>
                        <CardAnalytics iconName={'https://precision.nyc3.cdn.digitaloceanspaces.com/public/img/competitor/299/ac3701a8-b3d0-47e6-ba56-293cd0131604.png'} value={'7'} label={'MCLAREN'} percentage={'7 results (4,22%)'} noAvatar />
                    </Grid>
                    <Grid item lg={3} xl={3.2} spacing={2} sx={{zIndex: '2'}}>
                        <Div sx={{display: 'flex',flexDirection: 'column',gap: 1.3}}>
                            <Typography variant="h4" sx={{color: '#00c2a8',textShadow: '.2rem .2rem .2rem rgba(0, 0, 0, .5333333333333333)',fontSize: '18px'}}>CITAÇÃO DA CONCORRÊNCIA</Typography>
                            <Typography variant="body" sx={{color: 'white',textShadow: '.2rem .2rem .2rem rgba(0, 0, 0, .5333333333333333)',fontSize: '15px'}}>A citação da concorrência <span style={{color: '#00c2a8'}}>aumentou em 10%</span>, passando de 85 em abril para 94 no período atual.
                            </Typography>
                            <Typography variant="body" sx={{color: 'white',textShadow: '.2rem .2rem .2rem rgba(0, 0, 0, .5333333333333333)',fontSize: '15px'}}><span style={{color: '#00c2a8'}}>Nissan e Porsche</span> foram os mais frequentes, sendo mencionados nos conteúdos de Fórmula-E, principalmente nos resultados que citam o Enzo Fittipaldi como um dos novatos nos testes.</Typography>
                        </Div>
                    </Grid>
                </Grid>
            </Div>
            <Div sx={{width: '100%',height: '100vh',background: 'black'}}>
                <MapContainer center={[-23.5505,-46.6333]} zoom={13} maxZoom={18} minZoom={3} scrollWheelZoom={true} style={{height: '100%',width: '100%'}}>
                    <TileLayer
                        attribution='&copy; <a href="http://localhost:3000/mapa">Resolui TI</a>'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    <Marker position={[-23.5505,-46.6333]}>
                        <Popup>
                            São Paulo
                        </Popup>
                    </Marker>
                </MapContainer>
            </Div>
        </Div>
    );
}