import CardQuick from 'components/CardQuick';
import {Avatar,CardContent,CardHeader,Grid,LinearProgress,Stack,Tooltip,Typography,useMediaQuery} from '@mui/material';
import Div from 'ui-component/layouts/components/Div';
import {IconButton} from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import dayjs from 'dayjs';

export default function CardEquipe({data}) {
    const {nome_head,id_head,espaco_head,colaboradores}=data
    const xl=useMediaQuery(theme => theme.breakpoints.down('xl'));

    return (
        <CardQuick noWrapper sx={{width: '100%',flex: '0 0 auto',marginRight: '10px',boxShadow: 'none'}}>
            <Div id='teste2' sx={{position: 'relative'}}>
                <CardHeader
                    action={<IconButton sx={{color: 'black'}}><MoreHorizIcon /></IconButton>}
                    sx={{
                        position: "relative",
                        zIndex: 3
                    }}
                />
                <Div sx={{zIndex: 2,postion: 'relative'}} >
                    <Stack alignItems={'center'} sx={{p: theme => theme.spacing(0,2),mt: -2}}>
                        <Avatar sx={{width: 72,height: 72,mb: 2}} alt={'Ivan Netto'} src='IVanNetto.jpg' />
                        <Typography variant={"h3"} color={"black"}>
                            {nome_head}
                        </Typography>
                        <Typography variant={"h6"} color={"black"}>
                            @{id_head}
                        </Typography>
                    </Stack>
                    <Div sx={{width: '89%',m: '16px auto 0'}}>
                        <LinearProgress
                            variant={"determinate"}
                            color={"success"}
                            value={espaco_head}
                            sx={{
                                borderRadius: 4,
                                height: 8,
                                backgroundColor: '#E9EEEF'
                            }}
                        />
                    </Div>
                </Div>
            </Div>
            <Grid container sx={{marginTop: '80px'}} id='teste1'>
                {
                    colaboradores.map((colaborador) => {
                        return (
                            <Grid key={colaborador.nome} item xs={6}>
                                <CardContent sx={{p: 0}}>
                                    <Div sx={{position: 'relative'}}>
                                        <Div sx={{zIndex: 2,postion: 'relative'}}>
                                            <Stack alignItems={'center'} sx={{p: theme => theme.spacing(0,2),mt: -2}}>
                                                <Avatar sx={{width: 72,height: 72,mb: 2}} alt={'Ivan Netto'} src='IVanNetto.jpg' />
                                                <Typography variant={"h3"} color={"black"}>
                                                    {colaborador.nome}
                                                </Typography>
                                                <Typography variant={"h6"} color={"black"}>
                                                    @{colaborador.id}
                                                </Typography>
                                            </Stack>
                                            <Div sx={{width: '75%',m: '16px auto 0'}}>
                                                <LinearProgress
                                                    variant={"determinate"}
                                                    color={"success"}
                                                    value={colaborador.espaco}
                                                    sx={{
                                                        borderRadius: 4,
                                                        height: 8,
                                                        backgroundColor: '#E9EEEF'
                                                    }}
                                                />
                                            </Div>
                                            {
                                                colaborador.equipes.map((equipe) => {
                                                    return (
                                                        <Div key={equipe.nome} sx={{border: '1px solid #dedede',borderRadius: '5px',margin: '20px',marginTop: '40px'}}>
                                                            <Stack justifyContent={'center'} direction='row' spacing={2} sx={{border: '1px solid #dedede'}}>
                                                                <Typography variant={"h3"} color={"black"} padding={1}>
                                                                    {equipe.nome}
                                                                </Typography>
                                                            </Stack>
                                                            {
                                                                equipe.slots.map((slot) => {
                                                                    let diff=100;

                                                                    const data_inicial=dayjs()

                                                                    if(slot?.data_final) {
                                                                        const data_final=dayjs(slot?.data_final)
                                                                        diff=dayjs(data_final).diff(dayjs(data_inicial),'day')
                                                                    }

                                                                    let cor='';

                                                                    if(diff<=60) {
                                                                        cor='#f1c897'
                                                                        if(diff<=30) {
                                                                            cor='#f1a7a7'
                                                                        }
                                                                    }

                                                                    if(!slot.nome) return (
                                                                        <Stack key={slot.nome} justifyContent={'center'} alignItems={'center'} direction='row' spacing={2} marginTop={2}>
                                                                            <Typography variant={"h5"} color={"#black"}>
                                                                                -
                                                                            </Typography>
                                                                        </Stack>
                                                                    )
                                                                    return (
                                                                        <Div key={slot.nome} sx={{border: '0.5px solid #f2f2f2',padding: 1,display: 'flex',justifyContent: 'center',width: '100%',background: diff<=99&&cor}}>
                                                                            <Tooltip title={
                                                                                <Typography variant={"h5"} color={"white"}>
                                                                                    {diff<=0? `Vencido`:`Vence em: ${diff} dias`}
                                                                                </Typography>
                                                                            } followCursor disableHoverListener={diff===100} >
                                                                                <Div sx={{display: 'flex',justifyContent: 'space-between',minWidth: xl? '95%':'60%'}}>
                                                                                    <Typography variant={"h5"} color={"black"} textAlign='start'>
                                                                                        {slot.nome}
                                                                                    </Typography>
                                                                                    <Typography variant={"h5"} color={"black"}>
                                                                                        {slot.valor.toLocaleString('pt-BR',{style: 'currency',currency: 'BRL'})}
                                                                                    </Typography>
                                                                                </Div>
                                                                            </Tooltip>
                                                                        </Div>
                                                                    )
                                                                })
                                                            }
                                                            <Div sx={{border: '0.5px solid #f2f2f2',padding: 1,display: 'flex',justifyContent: 'center',width: '100%'}}>
                                                                <Div sx={{display: 'flex',justifyContent: 'space-between',minWidth: xl? '95%':'60%'}}>
                                                                    <Typography variant={"h5"} color={"black"} textAlign='start'>
                                                                        Ticket Médio: {equipe.ticket_medio.toLocaleString('pt-BR',{style: 'currency',currency: 'BRL'})}
                                                                    </Typography>
                                                                    <Typography variant={"h5"} color={"black"}>
                                                                        Valor Total: {equipe.valor_total.toLocaleString('pt-BR',{style: 'currency',currency: 'BRL'})}
                                                                    </Typography>
                                                                </Div>
                                                            </Div>
                                                        </Div>
                                                    )
                                                })
                                            }
                                        </Div>
                                    </Div>
                                </CardContent>
                            </Grid>
                        )
                    })
                }
            </Grid>
        </CardQuick>
    )
}