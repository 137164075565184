import {ArrowDropDown,ArrowDropUp} from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import {TableHead,TableRow,TableCell,IconButton,Tooltip,useTheme,useMediaQuery} from '@mui/material';
import {useState} from 'react';
import Div from 'ui-component/layouts/components/Div';

const renderSortIcon=(column,filters,setFilters,applyFilters) => {
  let filtroName=`order[${column.value}]`

  if(column.isNested) {
    filtroName=`order[${column.nested}]`
  }

  const onClick=(sort) => {
    if(filters[filtroName]===sort) {
      delete filters[filtroName];
      setFilters({...filters});
      applyFilters(false,{...filters});
      return;
    } else {
      delete filters[filtroName];
      setFilters((prevState) => {
        return {...prevState,[filtroName]: sort};
      });
      const filtersWithOrder={...filters,[filtroName]: sort};
      applyFilters(false,filtersWithOrder);
    }
  }

  return <Div sx={{display: 'flex',flexDirection: 'column',marginTop: '-5px'}}>
    <ArrowDropUp sx={{':hover': {cursor: 'pointer'},width: '20px',height: '20px',color: filters[filtroName]==='ASC'? 'black':'',marginBottom: '-5px'}} onClick={() => onClick('ASC')} />
    <ArrowDropDown sx={{':hover': {cursor: 'pointer'},width: '20px',height: '20px',color: filters[filtroName]==='DESC'? 'black':''}} onClick={() => onClick('DESC')} />
  </Div>
}

export default function TableHeadComponentWithOrder(props) {
  const {columns,moreData,actionAdd,border,setFilters,filters,applyFilters,minWidth,opcoesPrograma}=props;
  const [statesAdded,setStatesAdded]=useState({});
  const theme=useTheme();
  const xl=useMediaQuery(theme.breakpoints.down('xl'));
  let columnsHeader=columns;

  if(opcoesPrograma.length===0) {
    columnsHeader=columns.filter((column) => column.label.toLowerCase().includes('opc'));
  }

  return (
    <TableHead>
      <TableRow>
        {moreData&&(
          <TableCell width={70}>
            <Tooltip title="Adicionar mais">
              <IconButton aria-label="add user" sx={{...theme.typography.buttonListAdd}} onClick={() => actionAdd()}>
                <AddIcon />
              </IconButton>
            </Tooltip>
          </TableCell>
        )}
        {columnsHeader.map((column,index) => {
          let filtroName=`order[${column.value}]`
          if(column.isNested) {
            filtroName=`order[${column.nested}]`
          }
          if(column.ordenacao&&!filters[filtroName] && !statesAdded[filtroName]) {
            setStatesAdded((prevState) => {
              return {...prevState,[filtroName]: column.ordenacao};
            })
            setFilters((prevState) => {
              return {...prevState,[filtroName]: column.ordenacao};
            })
          }
          return (
            <TableCell key={`${column.label} = ${index}`} align={column.align} style={{fontWeight: 600}} sx={{borderLeft: border? '1px solid #85858547 !important':'',minWidth: minWidth&&xl? '200px':''}}>
              <Div sx={{display: 'flex',alignItems: 'center',justifyContent: column.align,width: '100%',gap: 2}}>
                {column.label}
                {
                  !column.label.toLowerCase().includes('opc')&&column.ordenacao&&renderSortIcon(column,filters,setFilters,applyFilters)
                }
              </Div>
            </TableCell>
          )
        })}

      </TableRow>
    </TableHead>
  );
}
