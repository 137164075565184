import PropTypes from 'prop-types';

// material-ui
import {useTheme} from '@mui/material/styles';
import {
  Avatar,Box,ButtonBase,
  InputAdornment,
  TextField,
  useMediaQuery,
  Autocomplete,
  ListItemText
  // ,FormControl,MenuItem,Select
} from '@mui/material';

// project imports
import LogoSection from '../LogoSection';
// import SearchSection from './SearchSection';
import ProfileSection from './ProfileSection';
import NotificationSection from './NotificationSection';
// import ShortcutSection from './ShortcutSection';

// assets
import {
  IconMenu2
  // ,IconMaximize
  ,IconSearch
} from '@tabler/icons';
// import MessagesDropdown from './MessagesDropdown';
// import {styled} from '@mui/system';
import {useContext} from 'react';
import {ConfigMenuContext} from 'contexts/ConfigMenu';
import {useState} from 'react';
import Div from 'ui-component/layouts/components/Div';
import {Close} from '@mui/icons-material';
import {useLocation} from 'react-router';
import {useSearchParams} from 'react-router-dom';
import {useDashboardPage} from 'contexts/DashboardPage';

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header=({handleLeftDrawerToggle,tableRef,leftDrawerOpened, scrolled}) => {
  const theme=useTheme();
  const [openSearch,setOpenSearch]=useState(false);
  const md=useMediaQuery(theme.breakpoints.down('md'));
  const [searchParamsGet,setSearchParams]=useSearchParams();
  const searchParams=Number(searchParamsGet?.get('page'))||0;
  const pathname=useLocation().pathname;
  const options=[{value: 0,label: 'Resumo'},{value: 1,label: 'Faturamento x Ano'},{value: 2,label: 'Entradas x Saídas'},{value: 3,label: 'Proposta e Ticket'},{value: 4,label: 'Contratos'},{value: 5,label: 'Serviços Extras'}]

  const {response}=useContext(ConfigMenuContext);
  const {setIsLoading}=useDashboardPage();

  const enterFullscreen=() => {
    const elem=tableRef.current;
    if(elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if(elem.mozRequestFullScreen) { // Firefox
      elem.mozRequestFullScreen();
    } else if(elem.webkitRequestFullscreen) { // Chrome, Safari, and Opera
      elem.webkitRequestFullscreen();
    }
  };

  const exitFullscreen=() => {
    if(document.exitFullscreen) {
      document.exitFullscreen();
    } else if(document.mozCancelFullScreen) { // Firefox
      document.mozCancelFullScreen();
    } else if(document.webkitExitFullscreen) { // Chrome, Safari, and Opera
      document.webkitExitFullscreen();
    }
  };

  const handleFullscreen=() => {
    if(!document.fullscreenElement) {
      enterFullscreen();
    } else {
      exitFullscreen();
    }
  };

  return (
    <>
      <Box onClick={handleFullscreen} sx={{display: 'none'}} />
      {/* logo & toggler button */}
      <Box
        sx={{
          width: !leftDrawerOpened? 140:260,
          display: 'flex',
          [theme.breakpoints.down('md')]: {
            // width: 'auto',
            bgcolor: 'inherit !important',
          },
          [theme.breakpoints.up('md')]: {
            // boxShadow: leftDrawerOpened? '9px 13px 15px -3px rgba(0,0,0,0.1)':'',
          },
          alignItems: 'center',
          // bgcolor: leftDrawerOpened? theme.palette.background.default:'inherit',
          p: '16px'
        }}
      >
        <ButtonBase sx={{borderRadius: '50%',overflow: 'hidden'}}>
          <Avatar
            variant="rounded"
            sx={{
              ...theme.typography.commonAvatar,
              ...theme.typography.mediumAvatar,
              transition: 'all .2s ease-in-out',
              background: `${theme.palette.reso.orange}`,
              bgcolor: 'white !important',
              borderRadius: '50%',
              ':hover': {bgcolor: '#c6c7e2 !important'},
              color: 'white',
              '&[aria-controls="menu-list-grow"],&:hover': {
                backgroundColor: '#2196f3',
              }
            }}
            onClick={handleLeftDrawerToggle}
            color="inherit"
          >
            <IconMenu2 color='gray' stroke={1.5} size="1.3rem" />
          </Avatar>
        </ButtonBase>
        <Box component="span" sx={{display: {xs: 'none',md: 'block'},ml: '20px'}}>
          <LogoSection />
        </Box>
      </Box>

      {
        openSearch&&(
          <Box sx={{background: 'white',width: !leftDrawerOpened||md? '100%':'calc(100% - 260px)',zIndex: 9999,position: 'absolute',right: 0}}>
            <Div sx={{display: 'flex',gap: 2,alignItems: 'center',p: 2}}>
              <TextField sx={{
                '& .MuiInputBase-root': {
                  height: '50px',
                },
                "& .MuiInputBase-input": {
                  background: 'white !important', // exemplo de estilo
                  height: '100%',
                },
                "& .MuiInput-root::after": {
                  borderBottom: 'green', // exemplo de estilo
                },
                "& .MuiInput-root::before": {
                  borderBottom: 'green', // exemplo de estilo
                }
              }} InputProps={{
                startAdornment: <InputAdornment position="start"><IconSearch color={'gray'} /></InputAdornment>
              }} placeholder='Pesquisar' variant='standard' fullWidth />
              <Close sx={{
                cursor: 'pointer',
                color: 'gray',
              }} onClick={() => setOpenSearch(false)} />
            </Div>
          </Box>
        )
      }

      {/* {
        response?.config?.admin === 'Y' && <ShortcutSection response={response} />
      } */}
      {
        pathname==='/dashboard-resumo'&&(
          <Autocomplete
            options={options}
            sx={{marginLeft: '20px',width: '220px','& .MuiInputBase-root': {height: '40px'}}}
            value={options.find((option) => option.value===searchParams)}
            getOptionLabel={(option) => option.label}
            onChange={(_,newValue) => {
              setSearchParams({page: newValue? newValue.value:null});
              setIsLoading(true)
              setTimeout(() => {
                setIsLoading(false)
              },100)
            }}
            clearIcon={null}
            renderOption={(props,item) => (
              <li {...props} key={item.key}>
                <ListItemText>{item.label}</ListItemText>
              </li>
            )}
            renderInput={(params) => <TextField {...params} label="Selecionar Página" />}
          />
        )
      }
      {/* {
        process.env.REACT_APP_ENVIRONMENT!='production'&&(
          <SearchSection />
        )
      } */}
      <Box sx={{flexGrow: 1}} />
      <Box sx={{flexGrow: 1}} />
      {/* {
        process.env.REACT_APP_ENVIRONMENT!='production'&&(
          <>
            <Box sx={{display: {xs: 'none',md: 'flex'}}}>
              <FormControl variant="standard">
                <SelectStyle classes={{'root': 'white'}} defaultValue={"en"} sx={{
                  ":before": {borderBottom: '0 !important'},
                  '& .MuiSelect-select': {
                    backgroundColor: 'inherit !important'
                  }
                }} inputProps={{color: 'white'}} >
                  <MenuItem value="en">Cliente 1</MenuItem>
                  <MenuItem value="fr">Cliente 2</MenuItem>
                </SelectStyle>
              </FormControl>
            </Box>

          </>
        )
      } */}
      {
        response?.config?.admin==='Y'&&(
          <>
            {/* <Box sx={{display: {xs: 'none',md: 'flex'},ml: 2}}>
              <ButtonBase sx={{borderRadius: '50%'}} onClick={() => handleFullscreen()}>
                <Avatar
                  variant="rounded"
                  sx={{
                    ...theme.typography.commonAvatar,
                    ...theme.typography.mediumAvatar,
                    transition: 'all .2s ease-in-out',
                    borderRadius: '50%',
                    bgcolor: 'white !important',
                    ':hover': {bgcolor: '#c6c7e2 !important'}
                  }}
                  aria-haspopup="true"
                  color="inherit"
                >
                  <IconMaximize color={'gray'} />
                </Avatar>
              </ButtonBase>
            </Box>
            <Box sx={{display: {xs: 'none',md: 'flex'},ml: 2}}>
              <ButtonBase sx={{borderRadius: '50%'}} onClick={() => setOpenSearch(true)}>
                <Avatar
                  variant="rounded"
                  sx={{
                    ...theme.typography.commonAvatar,
                    ...theme.typography.mediumAvatar,
                    transition: 'all .2s ease-in-out',
                    borderRadius: '50%',
                    bgcolor: 'white !important',
                    ':hover': {bgcolor: '#c6c7e2 !important'}
                  }}
                  aria-haspopup="true"
                  color="inherit"
                >
                  <IconSearch color={'gray'} />
                </Avatar>
              </ButtonBase>
            </Box>
            <MessagesDropdown /> */}
            <NotificationSection scrolled={scrolled} />
          </>
        )
      }
      <ProfileSection isAdmin={response?.config?.admin} scrolled={scrolled} />
    </>
  );
};

Header.propTypes={
  handleLeftDrawerToggle: PropTypes.func
};

export default Header;
